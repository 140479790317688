.verifyOTP-wrapper {
	height: 100%;
	background: $color-neutral-light;
	background-position: center;
	background-attachment: fixed;
	overflow-y: scroll;
	overflow-x: hidden;

	.otpWrapper {
		margin: $padding-s 37.5px;
	}
	.verifyOTP-subContainer {
		padding: 110px $padding-s 0 $padding-s;
	}
	.verifyOTP-heading {
		font-size: $font-size-xxlg;
		font-weight: $font-weight-bold;
		display: flex;
		justify-content: center;
	}
	.verifyOTP-subHeader {
		text-align: center;
		font-size: $font-size-lg;
		color: $color-secondary-text;
		max-height: 130px;
		min-height: 65px;
		overflow: hidden;

		p {
			margin-bottom: $padding-xs;
		}
	}

	.verifyOTP-input {
		margin: 52px 0 30px 0;

		.single-otp-input {
			max-width: 50px;
			min-height: 25px;
		}
	}

	.verifyOTP-resendCode {
		font-size: $font-size-lg;
		justify-content: center;
		color: $color-secondary-text;
		white-space: break-spaces;
		margin-inline: $padding-s;
		text-align: center;

		a {
			font-size: $font-size-lg;
			font-weight: $font-weight-bold;
			color: $color-main;
			text-decoration: none;
			cursor: pointer;
		}
	}

	.verifyOTP-otpError {
		font-size: $font-size-lg;
		margin-left: 28px;
		color: $color-error;
	}

	.verifyOTP-otpInfo {
		font-size: $font-size-md;
		margin: 0 $padding-s;
		color: $color-yellow-50;
	}

	.otpButtonWrapper {
		display: flex;
		justify-content: center;
		.otpButton {
			@extend .btn-primary;
			height: 54px;
			font-size: $font-size-mlg;
		}
	}

	.verify-input-style {
		font-size: 16px;
		border-radius: 10px;
		border: 1px solid #dadada;
		caret-color: $color-main;
		width: 40px;
		height: 40px;
		margin-right: 4px;
		margin-left: 4px;

		@media (max-width: $default-min-app-width) {
			width: 32px;
			height: 32px;
			margin-right: 2px;
			margin-left: 2px;
		}
	}

	input[type='tel' i] {
		padding: unset;
	}

	.input-separator {
		width: 16px;
		height: 1.5px;
		background: $color-neutral-90;
	}
}
