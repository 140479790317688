.facility-problem {
	&-wrapper {
		display: flex;
		flex-flow: column;
		height: 100%;
	}


	.upload-photo.hidden {
		display: none;
	}

	.error {
		.primary-input-error-message {
			margin-top: -$default-spacing;
		}
	}

	.hidden {
		height: 0;
	}

	&-label {
		display: flex;
		margin-bottom: $default-spacing;
		align-self: flex-start;
	}

	&-buttons {
		display: flex;
		justify-content: space-around;
	}
}
