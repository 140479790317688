.session-details-page {
	height: 100%;
	width: 100%;
	overflow-y: scroll;

	.session-name-wrapper {
		background: url(../../../../image/event-background.png);
		background-size: cover;
		background-position: center;
		color: $color-neutral-light;
		padding: $spacing-m;

		display: flex;
		align-items: end;

		p {
			padding-top: 60px;
		}
	}

	&-content {
		padding: $spacing-xlg $spacing-m;

		.session-description {
			margin-top: $spacing-xlg;
		}

		.session-status {
			margin-top: $spacing-xs;
		}

		.session-actions {
			margin-top: 50px;
			display: flex;
			gap: $spacing-s;

			.check-in-button {
				margin: 0px;
			}

			button {
				flex: 1;
			}
		}

		.session-speaker-title {
			margin-top: $spacing-lg;
			margin-bottom: $spacing-m;
		}

		.speaker-list {
			display: flex;
			overflow-x: auto;
			gap: $spacing-xlg;
			margin-inline: -$spacing-m;
			padding-inline: $spacing-m;

			.speaker-item {
				display: flex;
				flex-flow: column;
				cursor: pointer;
				width: 120px;
				img {
					width: 120px;
					height: 120px;
					border-radius: 50%;
					object-fit: cover;
				}

				.speaker-info {
					display: flex;
					flex-flow: column;
					gap: $spacing-xxs;
					margin-top: $spacing-m;
					overflow-wrap: break-word;
					word-wrap: break-word;
				}
			}
		}
	}
}

.session-status {
	display: flex;
	width: fit-content;
	border: 1px solid $color-neutral-dark;
	border-radius: $spacing-xxs;
	padding: 0px $spacing-xxs;

	&-label {
		line-height: 18px;
	}

	&.live {
		border-color: $color-red-50;

		.session-status-label {
			display: flex;
			align-items: center;
			gap: $spacing-xxs;
			color: $color-red-50;
			&::before {
				content: "";
				display: block;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background-color: $color-red-50;
			}
		}
	}
}
