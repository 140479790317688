.member-profile-page {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;

	.member-image {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			max-height: 450px;
		}
	}

	.profile-tabs {
		display: flex;
		padding: $padding-sml 0px 0px;
		border-radius: 20px 20px 0px 0px;
		margin-top: -20px;
		z-index: 1;
		background: $color-neutral-light;
		.tab {
			flex: 1;
			padding: 0px $spacing-md;
			position: relative;
			text-align: center;
			cursor: pointer;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-wrap: nowrap;
			.gradient-bar {
				margin-top: $padding-sm;
				background: $gradient-cgi;
				min-height: 4px;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				-webkit-transition: width 0.1s linear;
				-moz-transition: width 0.1s linear;
				-o-transition: width 0.1s linear;
				transition: width 0.1s linear;
				width: 0px;
			}

			&.active {
				p {
					color: $color-main;
					font-weight: $font-weight-bold;
				}

				.gradient-bar {
					width: 100%;
				}
			}
		}
	}

	.registration-form-details-profile-page-content {
		padding: $spacing-m;
		overflow-y: unset;
		flex: 1;
		overflow-wrap: break-word;
		word-wrap: break-word;

		&.scroll {
			overflow-y: auto;
		}

		ul {
			margin-top: 0px;
		}

		.mandatory-label {
			padding-bottom: $spacing-m;
		}

		.linked-in {
			.title-medium {
				font-size: $font-size-mmlg;
			}
			.member-social {
				display: flex;
				align-items: center;
				gap: $spacing-xs;

				img {
					width: 24px;
					height: 24px;
				}
			}

			&-link {
				background-color: $color-neutral-95;
				padding: $spacing-xs;
				overflow-wrap: break-word;
				word-wrap: break-word;
			}
			.not-connected {
				display: flex;
				flex-direction: column;
				align-items: center;

				p {
					color: var(--color-neutral-80);
				}
			}
		}
		.faq-wrapper {
			display: flex;
			flex-direction: column;
			padding: $spacing-m;
			gap: $spacing-xs;
			border: 1px solid #e8e8e8;

			.external-link {
				display: flex;
				gap: $spacing-xs;
				align-items: center;
				color: $color-main;

				.external-link-icon {
					color: $color-main;
					width: 16px;
					height: 16px;
				}
			}
		}

		.primary-input {
			margin-bottom: $spacing-lg;
		}

		.placeholderForVirtualKeyboard {
			height: 80vh;
		}
	}

	&-content {
		display: flex;
		flex-flow: column;
		overflow-y: auto;
	}
}
