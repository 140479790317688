input[type='time']::-webkit-calendar-picker-indicator {
	background: none;
}

input[type='number'] {
	-moz-appearance: textfield;
	margin: 0;
	padding: 2px 8px;
	text-align: right;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

.input {
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	-webkit-box-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 2px 40px 2px 8px;
	-webkit-overflow-scrolling: touch;
	position: relative;
	overflow: hidden;
	box-sizing: border-box;
	width: 100%;
	background-color: #efefef;
	border: none;
	border-bottom: 1px solid $color-main;
	min-height: 37px;
	font-size: $font-size-md;

	&:active,
	&:focus {
		border: 1px solid $color-main;
		margin-left: -1px;
		outline: none;
	}

	&:hover {
		border-color: $color-neutral-50;
		outline: none;
	}

	&::placeholder {
		color: $color-text-label;
		font-style: italic;
		font-size: $font-size-md;
	}
}

.input-error {
	border: 1px solid $color-error;
	color: $color-error;

	&::placeholder {
		color: $color-error;
	}
}

.autocomplete-input-wrapper {
	position: relative;
	margin-bottom: $default-spacing;

	.autocomplete-container {
		display: flex;
		flex-flow: column;
		align-items: center;
		border: solid 1px $color-neutral-dark;
		border-radius: 5px;
		position: absolute;
		background-color: $color-neutral-light;
		width: 100%;
		z-index: 5;
		max-height: 200px;
		overflow-y: scroll;
		overflow-x: hidden;

		.loading-wrapper {
			padding: $default-spacing;
		}

		.autocomplete-option {
			width: 100%;
			padding: $default-spacing;
			align-self: flex-start;
			height: 25px;
			border: solid $color-neutral-95 1px;
		}

		.autocomplete-option:hover {
			background-color: $color-neutral-95;
		}
	}
}
