.settings-wrapper {
	height: calc(95% - 15px);
	display: flex;
	flex-flow: column;
	margin-top: $padding-sml;
	overflow-y: auto;

	.version-information {
		text-align: center;
		width: 100%;
		font-size: $font-size-sm;
		margin-top: 20px;

		p {
			margin-bottom: 7px;
		}
	}

	.settings-header {
		padding-top: 10px;
	}

	.settings-change-time-format {
		padding-bottom: 20px;
	}

	.settings-subheading {
		font-weight: $font-weight-bold;
		font-size: 18px;
		line-height: $line-height-md;

		@media all and (max-width: 330px) {
			font-size: 16px;
		}
	}

	.settings-content {
		height: calc(90% - 15px);
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		padding: 0 15px;

		@media all and (max-width: 375px) {
			height: calc(80% - 15px);
		}

		.heading {
			margin: $default-spacing;
		}
	}

	.settings-logout-button-wrapper {
		margin-top: auto;
		margin-left: 15px;
		margin-right: 15px;
		text-align: center;
		display: flex;
		justify-content: center;
		flex-flow: column;
		gap: $spacing-xs;

		.button {
			margin: 0;
		}

		.heading {
			margin: $default-spacing 0;
		}
	}

	.settings-switcher-wrapper {
		box-shadow: 0 3px 8px rgb(0 0 0 / 10%);
		cursor: pointer;
		padding: 10px 15px;
		border-radius: 5px;
		display: flex;
		margin: 10px 5px;
		align-items: center;
		justify-content: space-between;
		text-decoration: none;
		min-height: 64px;

		span {
			font-weight: $font-weight-bold;
		}
	}
}

.buttonLogout {
	width: 100%;
	max-width: 375px;
}
