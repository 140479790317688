.event-page {
	overflow-y: scroll;
	height: 100%;
	display: flex;
	flex-direction: column;

	.loading-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
		background-color: $color-neutral-light;
		border-radius: 20px 20px 0px 0px;
		margin-top: -20px;
		padding-top: 50px;
	}

	.image-wrapper {
		img {
			height: 200px;
		}
	}

	&-header {
		background-size: cover;
		background-position: center;
		color: $color-neutral-light;

		&-full {
			flex: 1;
			width: 100%;
		}
	}

	&-warning-wrapper {
		display: flex;
		cursor: pointer;
		justify-content: space-between;
		align-items: center;
		background-color: $color-neutral-light;
		padding: $spacing-m;

		.icon-text {
			display: flex;
			align-items: center;
			gap: $spacing-m;
		}

		.warning-chevron {
			align-self: flex-end;
		}
	}

	&-content {
		border-radius: 20px 20px 0px 0px;
		margin-top: -20px;
		z-index: 1;
		background: var(--color-neutral-light);

		&-header {
			margin: $spacing-m;
		}
		.start-date {
			margin: 0;
			font-size: $font-size-md;
			font-weight: $font-weight-bold;
			color: $color-neutral-80;
		}
		&-headerContainer {
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: space-between;
			font-size: $font-size-mlg;
			font-weight: $font-weight-bold;
			border-top-left-radius: 16px;
			border-top-right-radius: 16px;

			.btn-wrapper {
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}

			.show-all {
				padding: 0px;
				margin: $spacing-m;

				.icon {
					width: 18px;
					height: 18px;
				}
			}
		}
		.menimeter-redirect {
			background-color: #afcdfb;
			margin: $spacing-m;
			border-radius: 12px;
			font-size: $font-size-mmlg;
			padding: $spacing-m;
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;

			.icon-wrapper {
				width: 40px;
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $color-main-30;
				border-radius: 50%;
			}
		}

		.no-sessions {
			display: flex;
			justify-content: center;
			align-items: center;

			p {
				color: $color-main;
			}
		}

		&-other-cards {
			.card-list {
				padding: 0 $spacing-m;
				padding-bottom: $spacing-lg;
				display: flex;
				flex-flow: column;
				gap: $spacing-s;
			}

			.card-list-horizontal {
				padding: 0 $spacing-m;
				padding-bottom: $spacing-lg;
				display: flex;
				overflow-x: auto;
				gap: $spacing-s;

				&-item {
					width: 150px;
					display: flex;
					justify-content: center;
					align-items: center;
					gap: $spacing-md;
					flex-flow: column;
					padding: 24px 0;
					@include sm-box-shadow;
					font-weight: $font-weight-semibold;
					cursor: pointer;
					text-align: center;
					color: black;
					text-decoration: none;
					background-color: #f8f8f8;
					flex-shrink: 0;
					.icon-wrapper {
						height: 64px;
						width: 64px;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						background: $gradient-cgi;

						svg {
							width: 24px;
							height: 24px;
						}
					}

					p {
						margin: 0;
					}
					.small-font {
						font-size: $font-size-sm;
					}
					&.wifi {
						grid-column-end: span 2;
					}
				}
			}
		}

		.session-items {
			display: flex;
			flex-direction: column;
			gap: $spacing-xs;
			margin: $spacing-xs $spacing-m;
		}
	}

	&-info {
		padding: $spacing-m;
		display: flex;
		align-items: center;
		flex-flow: column;
		flex-grow: 2;

		.event-info {
			padding: $spacing-lg 0;
		}

		p {
			margin: 0;
			font-size: $font-size-mlg;
			text-align: center;
		}
		.technology-session {
			display: flex;
			flex-flow: column;
			justify-content: center;
			padding: $spacing-m;
			gap: $spacing-m;

			.welcome-title {
				font-size: $font-size-mmlg;
				font-weight: $font-weight-medium;
				color: $color-main-0;
				margin-bottom: $spacing-md;

				&-info {
					font-size: $font-size-lg;
					font-weight: $font-weight-normal;
				}
			}
			.info-wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 50px;
				cursor: pointer;

				.info {
					font-size: $font-size-lg;
					font-weight: $font-weight-normal;
					color: $color-main;
					max-width: 250px;
				}
				.redirect-button {
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: $color-main-0;
					border-radius: 50%;
					width: 40px;
					height: 40px;

					.icon {
						width: 25px;
						height: 25px;
					}
				}
			}
			p {
				text-align: left;
				margin: 0;
			}
		}
	}
}

.event-page::-webkit-scrollbar {
	width: 0;
}

.warning-icon {
	color: $color-red-60;
	width: 20px;
	height: 20px;
}

.personal-agenda-list-item {
	padding: $spacing-m;
	background-color: #f2f1f9;
	display: flex;
	gap: $spacing-xxs $spacing-m;
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: center;

	.time {
		font-size: $font-size-xlg;
		font-weight: $font-weight-bold;
		margin: 0;
	}

	.name {
		font-size: $font-size-mmlg;
	}
	.location {
		font-size: $font-size-md;
		font-weight: bold;
		align-self: flex-start;
	}
	p {
		margin: 0;
	}
	&-live {
		border-image: $gradient-cgi-vertical 1 100%;
		border-width: 4px;
		border-style: solid;
		border-right: none;
	}
}
