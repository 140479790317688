.attendee-page-content {
    padding: $spacing-xxlg;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-xlg;
    overflow-y: auto;
    .member {
        display: flex;
        flex-direction: column;
        gap: $spacing-lg;
        text-align: center;
        &-image {
            display: flex;
            justify-content: center;
            align-items: center;

            .ph-item,
            .ph-row,
            .ph-col-12 {
                padding: 0;
                margin: 0;
                width: 130px;
                height: 130px;
                border-radius: 50%;
                border: none;
            }
            img {
                width: 130px;
                height: 130px;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        &-title,
        &-business-unit {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
        }

        &-business-unit {
            color: $color-neutral-0;
            opacity: 0.45;
        }
    }

    .contact-info {
        display: flex;
        flex-direction: column;
        gap: $spacing-xs;
        width: 100%;
        &-tile {
            display: flex;
            gap: $spacing-m;
            align-items: center;
            text-decoration: none;
            color: $color-neutral-dark;
            .icon {
                width: 36px;
                height: 36px;
            }

            .image-wrapper {
                width: 36px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }

    .actions {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;

        .attendee-page-button {
            width: 100%;
            margin: 0px;

            &.remove {
                border-color: $color-red-50;
                color: $color-red-50;
            }
        }
    }
}
