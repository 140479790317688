.profile-completion {
	height: 100%;
	background: $color-neutral-light;
	overflow-y: scroll;
	overflow-x: hidden;

	&-subContainer {
		height: 100%;
		margin: 40px $padding-s $padding-s $padding-s;
	}

	&-ui {
		display: flex;
		flex-direction: column;
		margin-top: 40px;

		&-txtField {
			margin: 0 0 $padding-xs 0;
			.primary-input-label {
				height: 29px;
				font-size: $font-size-mmlg;
				font-weight: $font-weight-bold;
				margin-bottom: unset;
				color: $color-text-label;
			}
			.input-wrapper input {
				height: 40px;
			}
			.input-wrapper input[type='text'] {
				font-size: $font-size-lg;
			}
		}
	}
	&-confirm-button-wrapper {
		display: flex;
		justify-content: center;
	}
	&-error {
		font-size: $font-size-lg;
		color: $color-error;
		height: 100%;
	}
}
