.event-invitations-page {
	overflow-y: scroll;
	&-content {
		margin: $spacing-m;
		display: flex;
		flex-flow: column;
		gap: $spacing-xlg;

		.no-invitations {
			text-align: center;
			margin-top: $spacing-xlg;
		}

		.event-invitation {
			padding-bottom: $spacing-m;
			border: 1px solid $color-neutral-95;
			box-shadow: 0px 1px 3px 1px #00000026;
			box-shadow: 0px 1px 2px 0px #0000004d;

			&-banner {
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				height: 130px;
				display: flex;
				flex-flow: column;
				justify-content: flex-end;

				&.loading {
					background-color: #f8f8f8;
					align-items: center;
					justify-content: center;
				}

				.status-event-invitation {
					display: flex;
					justify-content: center;
					padding: 5px 10px;
					font-size: $font-size-sm;
					font-weight: bold;
					width: fit-content;
					border-radius: 5px;
					margin: $spacing-m;

					p {
						margin: 0;
					}
				}

				.status-accepted {
					background-color: $color-green-95;
					color: $color-green-50;
				}
				.status-declined {
					background-color: $color-red-95;
					color: $color-red-50;
				}
			}

			&-content {
				padding: $spacing-m;

				.invitation-header {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-bottom: $spacing-m;
				}
			}

			&-actions {
				display: flex;
				justify-content: flex-end;
				padding: $spacing-m;
				gap: 8px;
			}
		}

		.event-empty-invitation {
			background-color: #f8f8f8;
			display: flex;
			justify-content: space-between;
			flex-flow: row;
			align-items: center;
			padding: $spacing-m;
			gap: $spacing-m;
			box-shadow: 0px 1px 3px 1px #00000026;
			box-shadow: 0px 1px 2px 0px #0000004d;

			.info {
				flex-grow: 1;
				text-align: left;
				font-weight: $font-weight-medium;
			}

			.number-wrapper {
				p {
					margin: 0;
				}
			}
		}
	}
}