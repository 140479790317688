.registration-feedback {
	display: flex;
    flex-flow: column;
    height: 100%;
	&-content {
		display: flex;
		flex-flow: column;
		justify-content: space-around;
		align-items: center;
		text-align: center;
		gap: 45px;
		overflow-y: auto;
		padding: 40px;
		padding-top: 200px;

		.headline-feedback {
			font-size: 30px;
			color: $color-neutral-dark;
			font-weight: $font-weight-normal;
			text-align: center;
		}

		.color-main {
			color: $color-main;
			font-weight: $font-weight-normal;
			text-align: center;
		}
		.color-description {
			color: $color-neutral-dark;
			font-weight: $font-weight-normal;
			text-align: center;
		}
		.color-head {
			color: $color-neutral-dark;
			font-weight: $font-weight-bold;
			text-align: center;
			line-height: 34px;
		}
		.open-info {
			margin-top: $spacing-m;
			color: $color-main;
			font-size: 18px;
		}

		.feedback-image {
			height: 100px;
			width: 100px;
			border-radius: 50%;
		}
	}
	.log-out{
		white-space: nowrap;
		font-weight: $font-weight-bold;
	}
}
.shadow-around {
	box-shadow: 0px 1px 3px 1px #00000026;
	box-shadow: 0px 1px 2px 0px #0000004d;
}
.padding-around {
	margin: 5rem 1rem 1rem 1rem;
	padding: 1rem 1rem 2rem 1rem;
}