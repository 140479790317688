.primary-input {
	&-label {
		color: $color-text-label;
		display: inline-block;
		font-size: $font-size-sm;
		font-weight: $font-weight-normal;
		line-height: 14px;
	}

	input::placeholder {
		font-style: normal;
		color: #a8a8a8;
	}

	.input-wrapper {
		position: relative;
		display: flex;
		align-items: center;

		input {
			text-align: start;
		}

		.icon-green {
			position: absolute;
			right: 10px;
		}
	}

	&-hint-text {
		font-size: $font-size-sm;
		color: $color-neutral-50;
		text-align: right;
		margin: $spacing-xs 0 0 0;
	}
}

.disabled {
	opacity: 0.5;

	label {
		color: $color-neutral-0;
	}
	.input-wrapper {
		input {
			background-color: $color-neutral-95;
			border-color: $color-disabled-state;
			color: $color-disabled-state;
			cursor: not-allowed;

			&:active,
			&:focus,
			&:hover {
				outline: none;
			}
		}
	}
}

.error {
	.primary-input {
		&-error-message {
			margin: 0;
			font-size: $font-size-md;
			color: $color-error;
		}

		&-hint-text {
			color: $color-error;
		}
	}

	.input-wrapper input {
		position: relative;
		border: 2px solid $color-error;
	}

	.icon-error {
		position: absolute;
		right: 10px;
		color: $color-error;
	}

	.primary-input-label {
		color: $color-error;
		font-size: $font-size-md;
		font-weight: bold;
	}
}

.dropdown-input-message-error {
	margin: 0;
	font-size: $font-size-md;
	color: $color-error;
}

.dropdown-label {
	&-error {
		color: $color-error;
		font-size: $font-size-md;
	}
}

.dropdown-input-error > div:first-of-type{
	border: 2px solid $color-error
}


