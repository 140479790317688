.broadcast-notification {
	.grayed-text {
		opacity: 0.5;
		font-size: $font-size-sm;
	}

	&-icon {
		display: flex;
		justify-content: center;
	}

	&-content {
		.header,
		.text {
			margin: 0;
			padding: 0 $spacing-m;
		}

		.header,
		.text {
			white-space: pre-line;
		}
	}
	&-counter {
		text-align: right;
		font-size: $font-size-sm;
		margin-right: $spacing-m;
		color: $color-neutral-70;
	}

	.checkbox-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
