.page-bottom-menu {
	align-items: center;
	background-color: $color-neutral-light;
	bottom: 0;
	display: flex;
	flex-direction: row;
	position: absolute;
	width: 100%;
	z-index: 3;
	@include md-box-shadow;

	.options-wrapper {
		display: flex;
		width: 60%;
		height: 100%;
		width: 100%;
		gap: $spacing-lg;
		margin: 0 $spacing-xlg;
	}

	.option {
		text-align: center;
		display: flex;
		flex-flow: column;
		justify-content: flex-start;
		align-items: center;
		flex: 1;

		&.profile-option {
			position: relative;

			.new-notifications {
				align-items: center;
				background-color: $color-red-50;
				border-radius: 50%;
				color: $color-neutral-light;
				display: flex;
				font-size: $font-size-sm;
				height: 8px;
				justify-content: center;
				padding: 5px;
				text-align: center;
				width: 8px;
				&-minimum-size {
					font-size: 8px;
				}
			}

			.icon-invalid-certificate,
			.new-notifications {
				position: absolute;
				right: 15px;
				top: -10px;
			}

			.icon-invalid-certificate {
				height: 20px;
				width: 20px;
			}
		}

		.custom-icon {
			margin: 8px 0;
		}

		p {
			color: $color-text;
			cursor: pointer;
			font-size: $font-size-md;
			font-weight: 500;
			margin: 5px 0;

			@media all and (max-width: 330px) {
				font-size: 13px;
			}
		}

		.icon-active,
		&:active .icon,
		&:active p,
		.active {
			color: $color-main !important;
		}

		.disabled {
			color: $color-disabled-state;
		}

		.purple-bar {
			background: $gradient-cgi;
			min-height: 4px;
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			-webkit-transition: width 0.3s linear;
			-moz-transition: width 0.3s linear;
			-o-transition: width 0.3s linear;
			transition: width 0.3s linear;
		}

		.active-bar {
			width: 100%;
		}
	}
	.qr-code-option {
		height: 100%;
		position: relative;
		display: flex;

		.qr-icon {
			align-items: center;
			background: $color-main;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			height: 55px;
			width: 55px;
			margin: 8px 0;
		}
		&.disabled {
			background: $color-neutral-dark;
			border: $color-neutral-dark;
			opacity: 1;
			pointer-events: none;
			cursor: not-allowed;
		}
	}
}
