.ask-question-page {
    display: flex;
    flex-flow: column;
    height: 100%;

    &-content {
        flex-grow: 1;
        overflow-y: scroll;
        margin: $spacing-xlg $spacing-m;

        &-details {
            margin-block: $spacing-m;
        }

        .ask-question-textarea {
            margin-top: $spacing-xs;
            margin-bottom: $spacing-xlg;
            width: 100%;
        }

        .checkbox-wrapper {
            margin-bottom: $spacing-xlg;
        }
    }
}
