.event-support-page {
	display: flex;
	flex-flow: column;
	height: 100%;
	&-content {
		display: flex;
		flex-flow: column;
		padding: $spacing-xlg $spacing-m;
		gap: $spacing-xlg;
		overflow-y: scroll;
		flex: 1;

	}

	.support-section {
		display: flex;
		flex-direction: column;
		gap: $spacing-m;

		.empty-state {
			background-color: #f2f1f9;
			display: flex;
			flex-direction: column;
			padding: $spacing-lg;
			gap: $spacing-lg;
			align-items: center;
			justify-content: space-between;

			.bug-icon {
				width: 48px;
				height: 48px;
			}

			.label {
				font-size: 18px;
			}

			.button {
				font-size: 16px;
				font-weight: 600;
				line-height: 20px;
			}
		}

		.report-a-problem-button {
			align-self: center;
			margin-top: $spacing-m;
		}
	}
}

.confirm-details {
	margin: 0px;
}