.entity-info-bottom-card {
	.entity-info-header {
		width: 100%;
		background-color: $color-neutral-80;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		height: 48px;
		display: flex;
		align-items: center;
		color: $color-neutral-light;

		.entity-status {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			font-size: $font-size-lg;
			font-weight: $font-weight-bold;

			.status {
				min-width: 21px;
				height: 21px;
				border-radius: 50%;
				border-width: 3px;
				border-style: solid;
				border-color: $color-neutral-light;
				margin: $default-spacing;
				@include sm-box-shadow;
			}

			.available {
				background-color: $color-status-free;
			}

			.booked {
				background-color: $color-status-fully-booked;
			}

			.blocked,
			.restricted,
			.areaRestricted,
			.areaDeactivated {
				background-color: $color-neutral-90;
			}

			.onlyCheckIns {
				background-color: $color-status-onlyCheckIns;
			}
		}

		.close-card {
			width: 50px;
			text-align: center;
			font-size: $font-size-lg;
			color: $color-neutral-light;
			margin-left: auto;

			.close {
				font-size: $font-size-xlg;
			}
		}
	}

	.entity-info-content {
		.name-and-area {
			margin: $default-spacing $default-spacing 0px $default-spacing;
			display: flex;
			flex-direction: column;
			font-size: $font-size-lg;
			color: $color-secondary-text;
			font-weight: $font-weight-bold;
			gap: 4px;

			.icon-favorite {
				position: absolute;
				right: 30px;
				stroke: #979797;
			}

			&-deskName {
				white-space: break-spaces;
				display: flex;
				flex-flow: wrap;
			}

			span {
				color: $color-text;
				font-weight: $font-weight-bold;
			}

			.status {
				font-size: $font-size-lg;
				color: $color-secondary-text;

				span {
					font-weight: normal;
				}
			}

			.location {
				font-size: $font-size-lg;
				color: $color-secondary-text;

				span {
					font-weight: normal;
				}
			}

			.capacity {
				font-size: $font-size-lg;
				color: $color-secondary-text;

				span {
					font-weight: normal;
				}
			}
		}

		hr {
			margin: 0 $default-spacing;
			border: none;
			height: 1px;
			background-color: $color-neutral-95;
		}

		.status-description {
			padding: 10px $default-spacing;
			display: flex;
			align-items: center;
			font-size: $font-size-sm;
			background-color: $color-bottom-entity-info-description;

			&-additional-result {
				background-color: $color-main-90;
			}

			&-approval-needed {
				background-color: $color-neutral-90;
			}
			.icon {
				font-size: $font-size-lg;
				margin-right: 10px;
			}
		}

		.features-content {
			display: flex;
			margin: 5px $default-spacing;
			max-height: 190px;
			overflow-y: hidden;
			overflow-x: auto;

			.feature-info {
				align-items: center;
				display: flex;
				width: 33%;
				color: $color-main;
				font-size: $font-size-md;

				@media (max-width: $breakpoint-sm) {
					width: 50%;
				}
			}

			.icon {
				margin: 0 5px;
				color: $color-main;
			}

			.icon-default-stroke,
			.custom-icon {
				stroke: $color-main;
				width: 15px;
				height: 15px;
				margin: 0 5px;
			}

			.icon-default-fill {
				fill: $color-main;
			}
		}

		.buttons-wrapper {
			display: flex;
			justify-content: space-between;

			.button {
				width: 100%;
				height: 48px;
				padding: $default-spacing 0;
				display: flex;
				justify-content: center;
				margin-bottom: $default-spacing;

				svg {
					align-items: center;
					margin: -2px 0px 0px 4px;
				}
			}
		}

		.ph-item {
			padding: 0;
			padding-top: 10px;
			border: none;
			margin: 0;

			.ph-row {
				height: 15px;
			}
		}
	}

	.features-holder {
		display: flex;
		flex-flow: row;
		overflow-x: scroll;
		padding: $default-spacing;
	}

	.entity-info-icon-wrapper {
		border-radius: 50%;
		background: $color-neutral-90;
		width: 32px;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 10px;
		margin-right: 10px;
		border: 3px solid $color-neutral-light;

		.custom-icon {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 15px;
			height: 15px;
			color: $color-neutral-dark;
		}
	}

	.content {
		margin: 10px $default-spacing;
		display: flex;
		flex-direction: row;
		font-size: $font-size-lg;
		color: $color-secondary-text;

		span {
			font-weight: $font-weight-bold;
		}

		.data {
			font-weight: normal;

			p {
				margin: 0px;
			}
		}

		&-inline {
			display: inline-block;
		}
	}

	.data {
		font-weight: $font-weight-normal;
		margin-left: 4px;
	}

	.card-header {
		font-weight: $font-weight-bold;
	}
}
.info-bottom-card {
	background-color: $background-color-main;
	margin: 0 $spacing-m;
	margin-bottom: $spacing-lg;
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	@include md-box-shadow;
	transition: max-height 0.5s;
	max-height: 0;
}

.info-bottom-card-opened {
	max-height: 400px;
	transition: max-height 0.5s;
}
