.registration-form {
	&-page {
		display: flex;
		flex-flow: column;
		height: 100%;
	}

	&-content {
		flex-grow: 2;
		overflow-y: scroll;
		padding: $spacing-lg;
		gap: $spacing-m;
		display: flex;
		flex-flow: column;

		.info-headline {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.color-headline {
				color: $color-main;
				font-weight: $font-weight-medium;
				&.error {
					color: $color-error;
					font-size: $font-size-md;
				}
			}
		}
		.dropdown {
			&-label {
				margin-bottom: $spacing-xs;
			}
		}
		.terms-and-conditions {

			.input-checkbox + .input-checkbox-label {
				padding: $spacing-m 0;
				font-weight: bold;
			}

			.input-checkbox + .input-checkbox-label:before {
				margin-right: $spacing-m;
			}
		}
		.required-field-block {
			padding: $padding-s;
			background-color: #f8f8f8;
		}
		.hyperlink {
			margin-left: 2px;
			color: $color-main;
			text-decoration: underline;
			cursor: pointer;
		}
		.checkbox-list {
			.checkbox-wrapper {
				background-color: $color-main-95;
				padding: $spacing-m;
				margin-bottom: 2px;

				.input-checkbox-label {
					font-weight: $font-weight-medium;
					font-size: 16px;
				}
				.input-checkbox + .input-checkbox-label:before {
					background-color: $color-main-95;
					margin-right: $spacing-m;
				}
				.input-checkbox:checked + .input-checkbox-label:before {
					background-color: $color-main;
				}
			}
		}
		.industry-drop-down,
		.warning {
			color: $color-yellow-0;
		}
	}
}

.cancel-information {
	color: $color-main;
	padding: $padding-xxs;
}

.color-headline {
	color: $color-main;
	font-weight: $font-weight-medium;
}
