.accordion {
	&-wrapper {
		margin-top: 20px;
	}

	&-title {
		border: 1px solid $color-main;
		cursor: pointer;
		padding: $default-spacing;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;

		&.active {
			flex-direction: column;
		}
	}

	&-content {
		font-size: 13px;
		line-height: 16px;
		text-align: justify;
		background-color: $color-neutral-95;
		padding: $default-spacing;
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;
		margin-bottom: $default-spacing;
	}

	&-item {
		&-icon {
			color: $color-neutral-dark;
			width: 18px;
			height: 18px;
		}
	}
}
