.profile-page {
	box-sizing: border-box;
	height: calc(100% - $default-spacing);
	overflow-y: auto;

	&-banner {
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		padding: $spacing-xs;
		padding-right: 0px;
		gap: $spacing-m;
		background-color: $color-neutral-95;
		align-items: center;
		box-sizing: border-box;
		width: 100%;
		&-picture {
			width: 64px;
			height: 64px;
			border-radius: 50%;
		}

		&-content {
			&-name {
				font-weight: $font-weight-medium;
				font-size: $font-size-mlg;
				color: $color-main;
			}
			flex: 1;
		}
	}

	&-heading {
		box-sizing: border-box;
		display: flex;
		font-size: $font-size-mlg;
		line-height: $line-height-sm;
		padding: 10px 0px;
		text-transform: uppercase;
		width: 100%;
		font-weight: $font-weight-normal;
	}

	&-content {
		align-items: center;
		background-color: $background-color-main;
		display: flex;
		flex-direction: column;
		padding-bottom: 40px;
		gap: $spacing-m;
		margin-top: 2rem;
		padding: 0px $spacing-m;

		&-wrapper {
			align-items: center;
			display: flex;
			flex-direction: column;
			margin-bottom: 30px;
		}
	}

	&-photo {
		height: 92px;
		margin-bottom: 24px;
		width: 92px;

		img {
			border-radius: 50%;
			height: 100%;
			object-fit: contain;
			width: 100%;
		}
	}

	&-name {
		color: $color-main;
		font-size: $font-size-mlg;
		font-weight: $font-weight-bold;
		margin: 0 0 $padding-xs 0;
	}

	&-organization-name {
		color: $color-neutral-80;
		font-size: $font-size-lg;
		font-weight: $font-weight-normal;
		line-height: $line-height-md;
		margin-top: $spacing-xxs;
		text-align: center;
	}

	&-job-title {
		color: $color-neutral-60;
		font-size: $font-size-mmlg;
		font-weight: $font-weight-normal;
		line-height: $line-height-lg;
		text-align: center;
	}

	&-description {
		margin: 0 25px 20%;

		p {
			margin: $default-spacing 0 0;
			text-align: center;
		}
	}

	.icon-invalid-certificate {
		height: 20px;
		margin-left: 5px;
		position: absolute;
		top: 33%;
		width: 20px;
	}

	.close-button {
		align-items: center;
		display: flex;
		justify-content: flex-end;
		margin: $default-spacing 0 0 $default-spacing;
		width: auto;

		p {
			font-size: $font-size-md;
			font-weight: $font-weight-medium;
			letter-spacing: $letter-spacing-lg;
			margin: 0;
			text-transform: uppercase;
		}
	}
}

.profile-item {
	align-items: center;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	gap: $spacing-m;
	width: 100%;
	background: #f8f8f8;
	height: 56px;

	&-heading {
		font-size: $font-size-lg;
		font-weight: $font-weight-normal;
		line-height: 20px;
		flex: 1 0 0;
	}
	&-img-container {
		display: flex;
		width: 56px;
		height: 56px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		background: #efefef;
	}
	&-icon {
		&-arrow {
			margin-left: auto;
			color: #767676;
			display: flex;
			padding: 8px;
			align-items: flex-start;
			gap: 8px;
			width: 16px;
			height: 16px;
		}
	}

	.new-notifications {
		align-items: center;
		background-color: $color-red-50;
		border-radius: 50%;
		color: $color-neutral-light;
		display: flex;
		font-size: $font-size-sm;
		height: 10px;
		justify-content: center;
		margin-left: $default-spacing;
		padding: 5px;
		text-align: center;
		width: 10px;
		&-minimum-size {
			font-size: 8px;
		}
	}
}
