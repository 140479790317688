.event-attendees-page {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	flex-flow: column;

	&-header {
		width: 100%;
	}

	&-content {
		color: $color-neutral-60;
		width: 100%;
		flex-grow: 2;
		display: flex;
		justify-content: center;
		flex-flow: column;
		height: 100%;

		&-search-wrapper {
			padding: $spacing-m;
			box-shadow: 0px 20px 20px 0px rgb(0 0 0 / 5%);

			.search-input {
				border-radius: 4px;
			}
		}

		&-filter-button {
			margin: $spacing-m 0;
			display: flex;
			justify-content: center;
			.attendees-filter-button {
				padding: $spacing-xs;
				color: $color-neutral-60;
				border-color: $color-neutral-60;

				.icon {
					color: $color-neutral-60;
				}
			}
			.attendees-filter-button-active {
				color: $color-main;
				border-color: $color-main;

				.icon {
					color: $color-main;
				}
			}
		}

		&-contacts-header {
			margin: 0 $spacing-m;
			font-size: 28px;
			font-weight: $font-weight-normal;
			line-height: 35px;
			color: $color-neutral-50;
			margin-bottom: 32px;
		}

		&-contacts-header-info {
			margin: 0;
			font-size: 14px;
			font-weight: $font-weight-normal;
			line-height: 20px;
			color: $color-neutral-60;
		}

		&-contacts-list {
			overflow-y: scroll;
			flex-grow: 2;
			padding-bottom: 75px;
			display: flex;
			flex-flow: column;

			.loader-wrapper,
			.loader-wrapper-end-list {
				display: flex;
				justify-content: center;
			}

			.no-results-wrapper {
				flex-grow: 1;
				display: flex;
				flex-flow: column;
				align-items: center;
				justify-content: center;

				.icon {
					width: 48px;
					height: 48px;
				}

				.headline {
					font-size: 24px;
					font-weight: bold;
					text-align: center;
				}

				.info {
					font-size: 14px;
					font-weight: $font-weight-normal;
					margin: 0;
					text-align: center;
				}

				.button-action-wrappers {
					margin-top: 48px;
					display: flex;
					gap: 8px;
				}
			}
		}
	}
}
