.speaker-page-content {
    padding: $spacing-xlg $spacing-m;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-xlg;
    overflow-y: auto;
    .member {
        padding: 16px;
        background-color: #f8f8f8;
        text-align: start;
        width: 100%;
        box-sizing: border-box;
        gap: $spacing-m;

        &-content {
            display: flex;
            gap: 24px;
            flex-direction: row;
            align-items: center;
        }

        &-bio {
            display: flex;
            flex-direction: column;
            gap: $spacing-xxs;
            white-space: pre-line;
        }

        img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
        }

        .sbu {
            opacity: 0.45;
        }

        .tags {
            display: flex;
            gap: $spacing-xs;

            .tag {
                color: $color-main;
            }
        }
    }

    .sessions {
        width: 100%;
        .session-items {
            margin-top: $spacing-m;
            display: flex;
            flex-direction: column;
            gap: $spacing-m;

            .centered {
                justify-self: center;
            }
        }
    }
}
