.date-time-input {
	&-wrapper {
		display: flex;
		flex-flow: column;
		align-items: flex-start;

		&-disabled {
			.datepicker-wrapper,
			.timepicker-wrapper {
				background-color: $color-neutral-95 !important;
			}
		}
	}

	&-label {
		font-size: $font-size-lg;
		margin-bottom: 4px;
	}

	&-content {
		display: flex;
		width: 100%;

		.datepicker-wrapper,
		.timepicker-wrapper {
			border-radius: 8px;
			border: 1px solid $color-neutral-95;
			color: $color-neutral-60;
			outline: none;
			box-shadow: none;
			font-weight: $font-weight-bold;
			font-size: $font-size-md;
			background: transparent;
			display: flex;
			align-items: center;
			padding: $spacing-s;
		}
		//make div position relative because we want the input to overflow the icon => so when user clicks on icon, the datepicker is opened
		.datepicker-wrapper {
			margin-right: $spacing-m;
			position: relative;
			width: 50%;

			//overridde mui elements to match the design
			.MuiInput-underline:before,
			.MuiInput-underline::after {
				content: none;
				border: none !important;
			}

			.MuiFormControl-root {
				display: inline-block !important;
				position: absolute;
				z-index: 2;
				width: 100%;

				.MuiFormHelperText-root.Mui-error {
					position: absolute;
					color: $color-error;
					font-size: $font-size-sm;
					margin-top: 6px;
					line-height: $line-height-xsm;
					left: -6px;
					bottom: -23px;
				}
			}

			.MuiInputBase-input {
				color: $color-neutral-60;
				font-weight: $font-weight-bold;
				font-size: $font-size-lg;
				margin-right: 10px;

				@media all and (max-width: 320px) {
					margin-left: 0;
				}
			}

			.icon {
				position: absolute;
				right: 10px;
			}

			// this dirty hack added because otherwise .filter-modal-sheet-wrapper hide current block.
			// The value is this big because the library for the modal-sheet wrapper assigns z-index: 9999999;
			.MuiDialog-root {
				z-index: 13000000 !important;
			}
		}
		.timepicker-wrapper {
			position: relative;

			.rc-time-picker-input {
				border: none;
				color: $color-neutral-60;
				font-size: $font-size-lg;
				font-weight: $font-weight-bold;
				padding: 0;
				outline: 0;
				//make the input overflow on top of the icon ( which has pos absolute ) so the icon is 'clickable'
				z-index: 1;
				background: transparent;
				-moz-appearance: textfield;

				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
			}

			.rc-time-picker {
				width: 100%;

				&-panel-combobox {
					display: flex !important;
				}
			}

			.icon {
				position: absolute;
				right: $spacing-s;
			}
		}

		@keyframes timeWrapperBackgroundIfFocus {
			from {
				opacity: 0.5;
			}
			to {
				opacity: 1;
			}
		}

		.focused-input {
			background-color: $color-neutral-95;
			animation-name: timeWrapperBackgroundIfFocus;
			animation-duration: 0.1s;
			animation-timing-function: linear;

			// this workaround is used to display the timepicker always on top of the input.
			.rc-time-picker-panel-placement-topRight,
			.rc-time-picker-panel-placement-topLeft {
				top: -195px !important;
				left: -12px !important;
			}
			// this workaround is used to display the timepicker always on the bottom of the input.
			.rc-time-picker-panel-placement-bottomLeft,
			.rc-time-picker-panel-placement-bottomRight {
				top: 40px !important;
				left: -12px !important;
			}
		}

		.icon {
			width: 24px;
			height: 24px;
		}
	}
}

.date-time-picker {
	.error-text {
		color: $color-error;
		margin-bottom: 0;
	}
}

.MuiDialog-root {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	position: relative !important;
	z-index: 5 !important;
}

.MuiBackdrop-root {
	position: absolute !important;
}

@media all and (min-width: $breakpoint-xxlg) and (min-height: $breakpoint-height-xlg) {
	.MuiDialog-root,
	.MuiBackdrop-root {
		border-radius: 20px;
	}
}
