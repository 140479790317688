.badge-card {
	height: 100vh;
	margin-top: $padding-sml;
	display: flex;
	flex-flow: column;
	overflow-y: auto;

	&-content {
		width: 100%;
		background-color: $background-color-main;
		align-items: center;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		flex: 1 1 auto;

		&-qr-container {
			width: 100%;
			position: relative;
			display: flex;
			flex-flow: column;
			align-items: center;

			.qr-code-card {
				margin: 32px 0;
				padding: 48px 0;
				width: calc(100% - 32px);
				display: flex;
				flex-flow: column;
				align-items: center;
				justify-content: center;
				box-shadow: 0px 0px 4px 2px #0000000d;
				box-shadow: 0px 0px 16px 4px #00000014;
				position: relative;

				img {
					width: 64px;
					height: 64px;
					border-radius: 50%;
					position: absolute;
					top: -32px;
				}

				.badge-qr {
					margin-top: 24px;
					width: 256px;
					height: 256px;
				}
				p {
					margin: 0;
					text-align: center;
				}
				.user-name {
					font-size: 18px;
					font-weight: $font-weight-normal;
				}
				.user-jobTitle {
					font-size: 14px;
					color: $color-neutral-80;
				}
			}
			.badge-wrapper {
				position: relative;
				display: flex;
				flex-flow: column;
				align-items: center;
				width: 100%;

				.progress-circle {
					#progress-bar {
						transition: all 1s linear;
					}
				}

				.loading-wrapper {
					width: 200px;
					height: 200px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 100px;
				}
				.hidden {
					display: none;
				}

				.text-countdown {
					font-size: 18px;
					margin: 0;
					position: absolute;
					text-align: center;
					top: 30%;

					span {
						color: $color-green-50;
					}
				}
			}
		}

		&-description {
			display: inline-block;
			width: 280px;
			margin: 0 auto;
			text-align: center;
			white-space: pre-line;
			padding-top: 15px;
			margin-bottom: 50px;

			span {
				font-weight: bold;
				color: $color-red-60;
			}
		}
	}

	.close-button {
		display: flex;
		align-items: center;
		margin: 0 0 0 auto;

		p {
			text-transform: uppercase;
			font-size: 14px;
			letter-spacing: 1.5px;
			font-weight: $font-weight-medium;
		}
	}

	.back {
		display: flex;
		align-items: center;
		margin: 0;
		text-transform: uppercase;
		font-size: 14px;
		letter-spacing: 1.5px;
		font-weight: $font-weight-medium;

		.icon {
			color: $color-main;
			padding-right: 5px;
			padding-left: 0;
			font-size: $font-size-sm;
		}
	}
}
