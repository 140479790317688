.feedback-banner {
	position: absolute;
	top: 20px;
	width: 100%;
	width: -moz-available;
	width: -webkit-fill-available;
	width: fill-available;
	z-index: 2;

	display: flex;
	justify-content: space-between;
	background: $color-neutral-light;
	margin: $spacing-m;

	@include sm-box-shadow;

	border: 1px solid $color-neutral-95;
	border-top: 5px solid $color-green-20;
	gap: 1rem;

	transition: opacity 1s;

	&-content {
		display: flex;
		gap: $padding-s;
		padding: $padding-sml $padding-s $padding-s $padding-s;
	}

	&.hidden {
		opacity: 0;
	}

	.icon-circle {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		width: 16px;
		height: 16px;
		border: 4px solid var($color-green-50);
		padding: 2px;

		.icon {
			color: $color-green-50;
			width: 16px;
			height: 16px;
		}
	}

	&-error {
		border-color: $color-red-50;
		.icon-circle {
			border-color: $color-red-50;
			.icon {
				color: $color-red-50;
			}
		}
	}

	.icon-close {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 20px;
		height: 20px;
		padding: $spacing-s;

		.icon {
			color: $color-neutral-dark;
			padding: 0 4px;
		}
	}
}
