.organization-switcher-page {
	display: flex;
	flex-flow: column;
	align-items: center;
	height: 100%;

	&-header {
		width: 100%;
	}

	&-content {
		flex-grow: 2;
		overflow-y: scroll;
		margin: 0 $spacing-m;
		&-title {
			color: $color-neutral-60;
			font-size: $font-size-xlg;
			margin: 0;
			font-weight: $font-weight-bold;
			margin-bottom: $spacing-m;
		}
		&-info {
			color: $color-neutral-60;
			font-size: $font-size-mmlg;
			margin: 0;
		}

		&-option {
			border: 1px solid $color-neutral-95;
			min-height: 50px;
			border-radius: 8px;
			margin: $spacing-s 0;
			padding: $spacing-xs;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			img {
				height: 30px;
			}
		}

		&-option-active {
			border-color: $color-main;
		}
	}
	.organization-login-error {
		color: $color-error;
		margin: $spacing-m;
	}
	.button-fixed-at-bottom {
		box-shadow: none;
	}
}
