.my-reports {
	&-header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.show-all {
			padding: 0;
		}
	}

	&-container-items {
		display: flex;
		flex-direction: column;
		gap: $spacing-m;
		.loading-wrapper {
			height: 80%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.no-reports-wrapper {
			display: flex;
			flex-flow: column;
			justify-content: center;
			align-items: center;
			height: 80%;

			.action-icon {
				width: 50px;
				margin-bottom: $spacing-s;
			}
			p {
				margin: 0 $spacing-s;
				font-size: $font-size-lg;
				text-align: center;
			}

			.bold {
				margin: $spacing-s;
				font-size: $font-size-mmlg;
			}
		}
	}

	&-page {
		height: 100%;
		display: flex;
		flex-flow: column;

		&-header {
			background-color: $color-neutral-light;
			@include sm-box-shadow;
			.content-switcher {
				margin: $spacing-s $spacing-m;
			}
		}

		&-content {
			padding: $spacing-xlg $spacing-m;
			display: flex;
			flex-direction: column;
			gap: $spacing-xlg;
			overflow-y: scroll;
		}
	}

	&-title-grid {
		display: grid;
		grid-template-columns: auto auto;
		align-items: center;
		justify-content: space-between;
		gap: 0px $spacing-m;

		.date {
			color: #767676;
		}
		.end {
			justify-self: end;
		}
	}

	&-add-comment {
		@include md-box-shadow;
		display: flex;
		padding: $spacing-xs;
		align-items: center;
		gap: $spacing-xs;

		.comment-item-member {
			min-width: 32px;
			height: 32px;
			background-color: $color-main-0;
		}

		.icon-wrapper{
			min-width: 32px;
			height: 32px;
			background-color: $color-main-50;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.textarea-holder {
			flex: 1;
			textarea::placeholder {
				white-space: pre-line;
				font-size: $font-size-md;
			}
		}
	}
}

//report item css = only contains styling for cards
.report-item {
	padding: $spacing-m;
	@include sm-box-shadow;
	background-color: $color-neutral-light;

	.name-wrapper {
		display: flex;
		align-items: center;
		.name {
			font-size: $font-size-mmlg;
			color: $color-neutral-60;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.location {
		font-size: 14px;
		font-weight: 700;
		margin: 0;
		color: $color-neutral-70;
	}
	.date {
		font-size: $font-size-lg;
		color: $color-neutral-70;
		margin: 0;
	}

	&-wrapper {
		display: flex;
		justify-content: space-between;
		gap: $spacing-s;
		align-items: center;
	}

	&-content-wrapper {
		display: grid;
		justify-content: space-between;
		grid-template-columns: auto auto;
		width: 100%;
		gap: $spacing-xxs;
	}

	&-location-time-wrapper {
		display: flex;
		align-items: center;
		gap: $spacing-s;
	}

	&-label {
		display: flex;
		justify-content: center;
		padding: $spacing-xxs $spacing-m;
		font-size: $font-size-sm;
		width: fit-content;
		border: 1px solid;

		&-inProgress {
			background-color: $color-yellow-90;
			border-color: $color-yellow-50;
		}

		&-resolved {
			background-color: $color-green-90;
			border-color: $color-green-50;
		}

		&-open {
			background-color: $color-red-90;
			border-color: $color-red-50;
		}

		&-dismissed {
			background-color: $color-red-95;
			color: $color-red-50;
		}
	}
}

.content-switcher-message {
	display: flex;
	gap: $spacing-xs;
	align-items: center;
}

.new-updates {
	min-width: 24px;
	height: 24px;
	color: $color-neutral-light;
	background-color: $color-main;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
