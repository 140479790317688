.map-view-container {
	background-color: $color-neutral-light;
	height: 100%;
	position: relative;

	.additional-info {
		width: 100%;
		font-size: $font-size-sm;
		text-align: center;
	}

	.filter-button-map-wrapper {
		position: absolute;
		display: flex;
		justify-content: center;
		width: 100%;
		z-index: 1;
		margin-top: 10px;
	}

	&-no-map {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.disabled {
		display: none;
	}
}

.map-view-container::before {
	box-shadow: 0 5px 5px $color-neutral-95;
	content: '';
	height: 10px;
	left: 0;
	position: absolute;
	top: -10px;
	width: 100%;
	z-index: 100;
}

.map-view-page-empty-mode {
	display: flex;
	flex-flow: column;
	height: 100%;
	position: relative;

	.selected-area {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin: $default-spacing 0;

		.area-name {
			text-align: center;
			font-weight: bold;
			margin: 0;
		}

		.close-button {
			position: absolute;
			right: 0;
		}
	}

	.switch-areas-wrapper {
		position: absolute;
		margin-bottom: 70px;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		transition: 0.5s;
		z-index: 1;

		.icon-wrapper-areas {
			background-color: white;
			box-shadow: 0 0 5px $color-neutral-95;
			border-radius: 50%;
			width: 40px;
			height: 40px;
			margin-right: 15px;
			margin-bottom: 15px;
			display: flex;
			justify-content: center;
			align-items: center;

			.icon {
				width: 15px;
				height: 15px;
			}
		}
	}

	.secondary-header-wrapper {
		z-index: 2;
		position: relative;
	}

	.grey-overlay {
		background-color: rgba($color: #333333, $alpha: 0.5) !important;
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 5;
		top: 0;
	}
}

//manipulating the div and canvas that gets created by conva
.konvajs-content {
	position: fixed !important;
	width: 100% !important;
	height: 100% !important;
	z-index: 0 !important;
	top: 0;
	left: 0;

	canvas {
		width: 100% !important;
		height: 100% !important;
		position: fixed !important;
		height: none !important;
	}
}
