.event-countdown-content {
	display: flex;
	flex-flow: column;
	align-items: center;
	text-align: center;
	padding: 55px $spacing-m 75px $spacing-m;
	color: $color-neutral-light;

	.event-info {
		padding: 0 $spacing-xxlg;
		text-shadow:
			-1px -1px 20px  rgba(0, 0, 0, 0.3),
			1px -1px 20px  rgba(0, 0, 0, 0.3),
			-1px 1px 20px  rgba(0, 0, 0, 0.3),
			1px 1px 20px rgba(0, 0, 0, 0.3);
	}
	.starts-in {
		font-size: $font-size-lg;
		font-weight: $font-weight-medium;
	}

	.change-registration {
		margin-top: $spacing-m;
		font-weight: bold;
		text-decoration: underline;
		cursor: pointer;
	}
}

.event-countdown-wrapper {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	height: 50%;
	padding-top: 10px;
	.countdown-container {
		display: flex;
		width: 100%;
		justify-content: space-evenly;
		align-items: center;
		gap: 3px;

		.dots-divider {
			font-size: 48px;
		}
		.box {
			display: flex;
			flex-flow: column;
			justify-content: center;
			align-items: center;
			padding: $spacing-m;
			background: linear-gradient(163.15deg, rgba(82, 54, 171, 0.2) 7.54%, rgba(255, 255, 255, 0.102) 93.49%);
			box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
			backdrop-filter: blur(3px);
			-webkit-backdrop-filter: blur(3px);
			text-transform: capitalize;

			.time-left {
				font-size: 48px;
				font-weight: 700;
			}

			p {
				margin: 0;
			}
		}
	}
}
