@import './broadcastNotifications';
@import './viewOfficeHours';

.modal-card {
	flex-direction: column;
	justify-content: start;
	display: none;
	position: absolute;
	z-index: 4;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: 0 auto;
	width: 100%;
	height: 100%;
	max-width: $default-max-app-width;
	max-height: $default-max-app-height;
	overflow: auto;

	.card {
		background-color: $color-neutral-light;
		border-radius: 32px;
		box-shadow: 1px 1px 4px 2px #a6a6a6;
		width: 85%;
		margin: $spacing-xlg 0;

		@media all and (max-width: $breakpoint-xxs) {
			width: 250px;
		}

		.close-card {
			text-align: right;
			padding-top: $default-spacing;

			.close {
				margin: 10%;
				font-size: $font-size-lg;
			}
		}

		.card-content {
			padding: 15% 10% 0 10%;

			@media all and (max-width: $breakpoint-xs) {
				padding: 5% 10% 0 10%;
			}

			.main-heading {
				text-transform: none;
				font-size: $font-size-mlg;
				margin: 0;
			}

			.card-header-status-wrapper {
				display: flex;
				justify-content: space-between;

				.card-header {
					font-weight: $font-weight-bold;
				}
			}

			.desk-additional-information {
				margin-top: $default-spacing;

				.desk-location {
					font-size: $font-size-sm;
					margin-bottom: 10px;
					font-weight: $font-weight-normal;
					color: $color-text;
				}

				.desk-labels {
					display: flex;
					justify-content: flex-start;
					margin: 20px 0;
					font-size: $font-size-xsm;
					flex-wrap: wrap;

					.disabled {
						svg {
							fill: grey;
						}
					}

					.icon {
						margin-right: 20px;
					}

					.label {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						margin: 0 5px 5px 5px;

						.icon {
							margin: 0;
						}

						.icon-default-fill {
							fill: $color-default-icon;
						}

						.icon-default-stroke,
						.custom-icon {
							stroke: $color-default-icon;
						}

						.label-text {
							font-size: $font-size-xsm;
						}
					}
				}

				.desk-name {
					font-size: $font-size-md;
					color: $color-secondary-text;

					span {
						color: $color-text;
						font-weight: $font-weight-bold;
					}
				}
			}

			.next-reservation-wrapper {
				display: flex;
				margin: 20px 0;

				.next-reservation-information {
					width: 100%;

					.section-header {
						font-weight: $font-weight-bold;
						font-size: $font-size-sm;
						margin-bottom: 5px;
					}

					.reservation-details-wrapper {
						display: flex;
						flex-direction: row;
						height: 100%;

						.reservation-details {
							font-size: $font-size-sm;
							margin-left: 10px;
							color: $color-text;
							width: 100%;

							p {
								margin: 0;
							}

							.reservation-name {
								margin-bottom: 5px;
							}

							.reservation-date-and-time {
								display: flex;
								justify-content: space-between;
							}
						}
					}
				}

				.booking-calendar-wrapper {
					font-size: $font-size-xsm;
					color: $color-main;
					display: flex;
					padding-top: 20px;

					.icon-wrapper {
						display: flex;
						justify-content: center;
						flex-direction: column;
					}

					.calendar-link {
						display: flex;
						justify-content: center;
						flex-direction: column;
						margin-left: 5px;
					}
				}
			}

			.requirement-wrapper {
				display: flex;
				margin-top: 15px;

				.requirement-details {
					width: 100%;

					p {
						margin: 0;
					}

					.requirement-date-and-time {
						display: flex;
						justify-content: space-between;

						.requirement-time-wrapper {
							display: flex;
							align-items: center;
							background-color: $color-input-reservation-detail;
							border-radius: 5px;
							padding: 5px;

							.time-input {
								margin-left: 5px;
								font-size: $font-size-sm;

								.requirement-time {
									font-weight: $font-weight-bold;
								}
							}
						}

						.requirement-date-wrapper {
							display: flex;
							align-items: center;
							background-color: $color-input-reservation-detail;
							border-radius: 5px;
							padding: 5px;

							.date-input {
								margin-left: 5px;
								font-size: $font-size-sm;

								.requirement-date {
									font-weight: $font-weight-bold;
								}
							}
						}
					}

					.requirement-location {
						margin-top: $default-spacing;

						.requirement-location-wrapper {
							display: flex;
							align-items: center;
							background-color: $color-input-reservation-detail;
							border-radius: 5px;
							padding: 5px;

							.location-input {
								margin-left: 5px;
								font-size: $font-size-sm;

								.location-text {
									font-weight: $font-weight-bold;
								}
							}
						}
					}
				}

				.booking-calendar-wrapper {
					font-size: $font-size-xsm;
				}
			}

			.booking-information-wrapper {
				.info-header {
					font-weight: $font-weight-bold;
					font-size: $font-size-sm;
					margin-bottom: 5px;
				}

				.info-data {
					font-size: $font-size-xsm;
					color: $color-text;
				}

				.table-info-wrapper {
					display: flex;

					.table-id {
						margin-left: 25%;
					}
				}

				.date-time-info-wrapper {
					display: flex;

					.date {
						margin-left: 25%;
					}
				}
			}

			.placeholder-container {
				.ph-item {
					padding: 0;
					border: none;

					.ph-row {
						height: 16px;
						padding: 0;
						width: 100%;

						.ph-header {
							width: 50%;
						}
					}

					.ph-section {
						margin-top: $default-spacing;
						height: 50px;
					}
				}
			}

			.member-card-placeholder {
				display: flex;
				align-items: center;
				flex-direction: column;
				padding: $default-spacing;
				margin-bottom: $default-spacing;

				.ph-picture {
					width: 150px;
					height: 150px;
					border-radius: 50%;
				}

				.ph-row {
					flex-direction: row;
					width: 100%;
				}
			}
		}

		.health-questionnaire-info {
			font-size: $font-size-sm;
			color: $color-main;
			margin: 0;
			text-decoration: underline;

			p {
				margin: 0;
			}
		}

		.disabled-HQ {
			display: none;
		}

		.buttons-wrapper {
			display: flex;
			justify-content: center;
			flex-flow: column;
			padding: 30px 32px 30px 0px;

			.button {
				margin-top: 8px;
			}

			.action-button {
				width: 100%;
				margin: 5px 0;
			}
		}

		.next-bookings-buttons-wrapper {
			text-align: center;
			padding: 5% 10% 15% 10%;

			.button {
				width: 100%;
				margin: 5px 0;
			}

			.action-button {
				width: 100%;
				margin: 5px 0;
				border-radius: 10px;
			}
		}

		.next-bookings-buttons-wrapper {
			text-align: center;
			padding: 5% 10% 15% 10%;

			.button {
				width: 100%;
				margin: 5px 0;
			}

			.action-button {
				width: 100%;
				margin: 5px 0;
				border-radius: 10px;
			}
		}

		.report-problem-card {
			.report-problem-text-wrapper {
				text-align: center;
				height: 300px;
				overflow: auto;

				#outlined-multiline-flexible,
				#outlined-multiline-flexible:focus {
					border-color: $color-main;
					outline: none;
					user-select: text;
					-webkit-user-select: text;
					-moz-user-select: text;
					-ms-user-select: text;
				}
			}

			.buttons-wrapper {
				text-align: center;
				padding: 0 10%;
			}
		}

		.checkoutCard.card-content {
			.card-header {
				text-transform: initial;
				margin-bottom: 10px;
				width: 100%;
			}

			.table-info-wrapper {
				display: flex;
			}

			.info-data,
			.info-header {
				font-size: $font-size-md;
			}

			.info-data {
				padding-left: 5px;
				font-weight: $font-weight-bold;
			}

			.info-header {
				font-weight: $font-weight-light;
				color: $color-secondary-text;
			}

			.table-name {
				margin-top: 20px;
			}
		}

		.confirmation-wrapper {
			text-align: center;

			.header,
			.header-bottom-line {
				font-size: $font-size-xlg;
				padding: 0 $default-spacing;
				font-weight: $font-weight-bold;
			}

			.text {
				padding: $default-spacing;
				overflow-wrap: break-word;
			}

			.header-bottom-line {
				@include horizontal-line-at-center;
			}

			.info {
				padding: 0 $default-spacing;
			}

			.icon,
			.custom-icon,
			.colleague-not-found-image {
				width: 48px;
				height: 48px;
			}

			.warning-icon {
				width: 100%;
			}

			&-icon {
				display: flex;
				justify-content: center;
				align-items: center;

				.icon-wrapper {
					border-radius: 50%;
					border: 5px solid $color-red-50;
					padding: 5px;
					width: 60px;
					height: 60px;
					display: flex;
					justify-content: center;
					align-items: center;

					.icon {
						width: 45px;
						height: 45px;
					}
				}
			}
			.button-red {
				background-color: $color-error;
				border-color: $color-error;
			}

			.name-error {
				font-size: $font-size-sm;
				color: $color-neutral-60;
				margin: 0;
			}

			.button {
				font-size: $font-size-mmlg;
				font-weight: $font-weight-semibold;
			}
			.close-button {
				color: $color-neutral-60;
			}
		}

		.summary-confirmation {
			&-wrapper {
				padding: 0 10%;

				.buttons-wrapper {
					display: flex;
					justify-content: center;
				}
			}

			&-header {
				@extend .heading;
				text-transform: capitalize;
			}

			&-list {
				list-style: none;
				padding-left: 0;
				margin-bottom: 0;
				margin-top: $default-spacing;
			}

			&-item {
				display: flex;
				align-items: center;
				font-weight: $font-weight-bold;
				font-size: $font-size-lg;

				.icon {
					margin-right: 5px;
					height: 16px;
				}
			}

			&-details {
				opacity: 0.5;
				margin: 5px 0 10px 0;
				overflow: hidden;
				margin-left: 20px;
				font-weight: $font-weight-normal;
				font-size: $font-size-md;
				line-height: $line-height-md;

				pre {
					white-space: pre-wrap;
					margin-top: 0;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					margin-bottom: 0;
				}
			}

			&-pictures {
				span {
					font-weight: $font-weight-bold;
				}

				.icon {
					height: 16px;
					margin-right: 6px;
				}
			}

			&-picturename {
				opacity: 0.5;
				display: block;
				max-width: 95%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.card-entity {
			&-wrapper {
				padding: 10% 10% 0 10%;
				text-align: left;
			}

			&-label {
				font-size: $font-size-lg;
				line-height: $line-height-lg;
				font-weight: $font-weight-bold;
			}

			&-labels {
				display: flex;
				align-items: flex-end;
				flex-wrap: wrap;

				.label {
					padding: 0 2px;

					.icon,
					.custom-icon {
						width: 25px;
						height: 25px;
					}

					.icon-default-fill {
						fill: $color-main;
					}

					.icon-default-stroke {
						stroke: $color-main;
					}
				}
			}

			&-time {
				font-size: $font-size-md;
				margin-left: 5px;
			}

			&-name {
				padding-left: 3px;

				&-highlighted {
					color: var(--color-text-special);
					font-weight: $font-weight-bold;
				}
			}
		}

		.btn-delete {
			.icon {
				width: 15px;
				height: 15px;
			}
		}
	}

	.error-text.body {
		padding: 0 20px;
	}
}

.additional-error {
	&-info {
		padding: 20px 20px 0 20px;
		font-size: $font-size-sm;
		opacity: 0.5;
	}

	&-statusCode,
	&-name,
	&-traceKey {
		margin: 5px;
		text-align: left;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 200px;
		display: inline-block;
	}

	&-statusCode {
		margin-right: 0;
	}

	&-name {
		margin-left: 0;
	}
}

.show {
	display: flex;
	align-items: center;
}

.confirmation-data-privacy-icon-wrapper {
	display: flex;
	justify-content: center;

	.icon {
		height: 50px;
		width: 50px;
	}
}

.confirmation-data-privacy {
	padding: 20px;

	.buttons-wrapper {
		padding: 0 !important;

		.data-privacy-buttons {
			margin: 0;
		}
	}
}

.session-description {
	white-space: pre-line;
}
