//Button sizes
.full-width {
	width: 100%;
	margin: 0 16px;
}

.half-width {
	width: 50%;
	margin: 0 16px;
}

.variable-width {
	width: fit-content;
	padding: 0 40px;
}

.auto-width {
	width: auto;
}

.huge-height {
	height: 56px;
	font-size: $font-size-mlg;
}

.regular-height {
	font-size: $font-size-lg;
}

//For cases (with or without icon) where the button’s horizontal padding would be less than 24px,
//a smaller font size or icon size might be allowed, never smaller than 14pt (text) or 16px (icon) to ensure readability.
.button .icon {
	width: 24px;
	height: 24px;
}

.button,
.button:focus {
	outline: none;
	border-style: solid;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-weight: $font-weight-semibold;
	padding: 6px 16px;

	&.small-height {
		height: 30px;
		width: 30px;
		font-size: $font-size-sm;
		padding: 8px;

		.icon {
			width: 14px;
			height: 14px;
		}
	}
}

// Button variants

.primary {
	background-color: $button-variant-primary-background;
	border-color: $button-variant-primary-border;
	color: $button-variant-primary-color;

	.icon {
		color: $button-variant-primary-color;
	}

	&:hover {
		background-color: $button-variant-primary-background-hover;
		border-color: $button-variant-primary-border-hover;
		color: $button-variant-primary-color-hover;
		box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

		.icon {
			color: $button-variant-primary-color-hover;
		}
	}

	&:focus {
		background-color: $button-variant-primary-background-focus;
		border-color: $button-variant-primary-border-focus;
		color: $button-variant-primary-color-focus;

		.icon {
			color: $button-variant-primary-color-focus;
		}
	}

	&:active {
		background-color: $button-variant-primary-background-pressed;
		border-color: $button-variant-primary-border-pressed;
		color: $button-variant-primary-color-pressed;

		.icon {
			color: $button-variant-primary-color-pressed;
		}
	}

	&:disabled {
		background-color: $button-variant-primary-background-disabled;
		border-color: $button-variant-primary-border-disabled;
		color: $button-variant-primary-color-disabled;
		cursor: default;

		.icon {
			color: $button-variant-primary-color-disabled;
		}

		&:hover {
			box-shadow: none;
		}
	}
}

.secondary {
	background-color: $button-variant-secondary-background;
	border-color: $button-variant-secondary-border;
	color: $button-variant-secondary-color;
	box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3);

	.icon {
		color: $button-variant-secondary-color;
	}

	&:hover {
		background-color: $button-variant-secondary-background-hover;
		border-color: $button-variant-secondary-border-hover;
		color: $button-variant-secondary-color-hover;

		.icon {
			color: $button-variant-primary-color-hover;
		}
	}

	&:focus {
		background-color: $button-variant-secondary-background-focus;
		border-color: $button-variant-secondary-border-focus;
		color: $button-variant-secondary-color-focus;

		.icon {
			color: $button-variant-secondary-color-focus;
		}
	}

	&:active {
		background-color: $button-variant-secondary-background-pressed;
		border-color: $button-variant-secondary-border-pressed;
		color: $button-variant-secondary-color-pressed;

		.icon {
			color: $button-variant-secondary-color-pressed;
		}
	}

	&:disabled {
		background-color: $button-variant-secondary-background-disabled;
		border-color: $button-variant-secondary-border-disabled;
		color: $button-variant-secondary-color-disabled;
		box-shadow: none;
		cursor: default;

		.icon {
			color: $button-variant-secondary-color-disabled;
		}

		&:hover {
			box-shadow: none;
		}
	}
}

.tertiary {
	background-color: $button-variant-tertiary-background;
	border: none;
	color: $button-variant-tertiary-color;

	.icon {
		color: $button-variant-tertiary-color;
	}

	&:hover {
		background-color: $button-variant-tertiary-background-hover;
		color: $button-variant-tertiary-color-hover;

		.icon {
			color: $button-variant-tertiary-color-hover;
		}
	}

	&:focus {
		background-color: $button-variant-tertiary-background-focus;
		border: none;
		color: $button-variant-tertiary-color-focus;

		.icon {
			color: $button-variant-tertiary-color-focus;
		}
	}

	&:active {
		background-color: $button-variant-tertiary-background-pressed;
		color: $button-variant-secondary-color-pressed;

		.icon {
			color: $button-variant-secondary-color-pressed;
		}
	}

	&:disabled {
		background-color: $button-variant-tertiary-background-disabled;
		border: none;
		color: $button-variant-tertiary-color-disabled;
		cursor: default;

		.icon {
			color: $button-variant-tertiary-color-disabled;
		}
	}
}

.empty {
	background-color: $button-variant-empty-background;
	border: 1px solid $color-main;
	color: $button-variant-empty-color;

	.icon {
		color: $button-variant-empty-color;
	}

	&:hover {
		background-color: $button-variant-empty-background-hover;
		color: $button-variant-empty-color-hover;

		.icon {
			color: $button-variant-empty-color-hover;
		}
	}

	&:focus {
		background-color: $button-variant-empty-background-focus;
		color: $button-variant-empty-color-focus;

		.icon {
			color: $button-variant-empty-color-focus;
		}
	}

	&:active {
		background-color: $button-variant-empty-background-pressed;
		color: $button-variant-empty-color-pressed;

		.icon {
			color: $button-variant-empty-color-pressed;
		}
	}

	&:disabled {
		background-color: $button-variant-empty-background-disabled;
		border: none;
		color: $button-variant-empty-color-disabled;
		cursor: default;

		.icon {
			color: $button-variant-empty-color-disabled;
		}
	}
}

.outline {
	border-width: 1px;
	background-color: $button-variant-outline-background;
	border-color: $button-variant-outline-border;
	color: $button-variant-outline-color;

	.icon {
		color: $button-variant-secondary-color;
	}

	&:hover {
		background-color: $button-variant-outline-background-hover;
		border-color: $button-variant-outline-border-hover;
		color: $button-variant-outline-color-hover;

		.icon {
			color: $button-variant-outline-color-hover;
		}
	}

	&:focus {
		background-color: $button-variant-outline-background-focus;
		border-color: $button-variant-outline-border-focus;
		color: $button-variant-outline-color-focus;

		.icon {
			color: $button-variant-outline-color-focus;
		}
	}

	&:active {
		background-color: $button-variant-outline-background-pressed;
		border-color: $button-variant-outline-border-pressed;
		color: $button-variant-outline-color-pressed;

		.icon {
			color: $button-variant-outline-color-pressed;
		}
	}

	&:disabled {
		background-color: $button-variant-outline-background-disabled;
		border-color: $button-variant-outline-border-disabled;
		color: $button-variant-outline-color-disabled;
		box-shadow: none;
		cursor: default;

		.icon {
			color: $button-variant-outline-icon-color-disabled;
		}

		&:hover {
			box-shadow: none;
		}
	}
}

.negative {
	background-color: $button-variant-negative-background;
	border-color: $button-variant-negative-border;
	color: $button-variant-negative-color;
}
.negative:focus {
	background-color: $button-variant-negative-background-pressed;
	border-color: $button-variant-negative-border-pressed;
	color: $button-variant-negative-color-pressed;
}
.negative:disabled {
	background-color: $button-variant-negative-background-disabled;
	border-color: $button-variant-negative-border-disabled;
	color: $button-variant-negative-color-disabled;
	cursor: default;
}

//Specific types of buttons
.btn-link {
	text-transform: uppercase;
	border: none;
}

.button-fixed-at-bottom-static {
	position: static;
	min-height: 50px;
	width: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
	padding-bottom: 50px;
}

.button-fixed-at-bottom {
	background: $color-neutral-light;
	position: absolute;
	bottom: 0;
	height: 100px;
	box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
	width: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
	max-width: 450px;
	left: 0;
	z-index: 10;
}

.button-fixed-at-bottom-buffer {
	height: 100px;
}

.round-corners {
	border-radius: 100px;
}
