.qr-reader-page {
	height: 100%;
	display: flex;
	flex-direction: column;
	.qr-reader-wrapper {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: $color-neutral-light;
		background-position: center;
		background-attachment: fixed;
		font-size: 18px;
		text-align: center;
		flex: 1;

		.button-wrapper {
			width: 100%;
			margin: 0px 16px;
			display: flex;
			justify-content: center;
		}

		&-action {
			font-size: $font-size-xlg;
			font-weight: $font-weight-bold;
			display: flex;
			justify-content: center;
			color: $color-main;
			margin-bottom: $spacing-m;

			p {
				margin: 0;
			}

			.word-placeholder {
				border-bottom: 2px solid $color-main;
				min-width: 100px;
				text-align: center;
				margin-left: 5px;
			}
		}

		.info-icons-wrapper {
			margin-top: $default-spacing;
			margin-bottom: 15%;
			display: flex;
			justify-content: center;
			align-items: center;

			.icon-wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin: 0 $default-spacing;

				.icon {
					font-size: 40px;
				}

				p {
					margin: 0;
					font-size: $font-size-sm;
				}
			}
		}

		.check-in-toggle {
			display: flex;
			gap: $spacing-m;
			margin-top: $spacing-m;
			align-items: center;
			justify-content: center;
		}

		.check-in-stats {
			margin-top: $spacing-m;
		}

		.session-drop-down {
			text-align: left;
		}

		.qr-reader-content {
			margin-inline: $spacing-xxlg;
		}
	}

	.desk-qr-reader-button-wrapper {
		margin: 40px 0;
	}
}
