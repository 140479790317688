.heading {
	text-align: left;
	text-transform: uppercase;
	@extend .font-lg;
	@include horizontal-line-left;
}

.main-heading {
	flex: 0 1 auto;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 40px;
	padding: 0 $default-spacing;

	@include horizontal-line-at-center;
	@extend .font-xlg;

	@media all and (max-width: 375px) {
		font-size: 21px;
	}

	@media all and (max-width: 340px) {
		font-size: 19px;
	}
}

.bold {
	font-weight: bold;
}

.section-heading {
	color: $color-main;
	font-weight: $font-weight-bold;
	font-size: $font-size-mlg;
	margin: $padding-s;
}

//used for pages that show details. example -> <p class="section-detail"> Label: <span>detail</span></p>
@mixin section-detail {
	color: $color-neutral-60;
	font-size: $font-size-lg;
	font-weight: $font-weight-bold;
	margin: $spacing-m;
	span {
		font-weight: $font-weight-normal;
	}
}
.subheading {
	font-weight: $font-weight-bold;
	font-size: 18px;
	line-height: $line-height-md;
}

.header-title {
	font-weight: $font-weight-bold;
	font-size: 18px;
	line-height: $line-height-md;
	margin-top: -47px;
	margin-bottom: 29px;
	text-align: center;
}

.breadcrumbs {
	font-weight: $font-weight-normal;
	font-size: 16px;
	line-height: $line-height-md;
	color: $color-neutral-80;
}

//CSS classes used for designing the header of pages - including back/close button
.header-wrapper {
	flex: 0 1 auto;
	display: flex;
	justify-content: space-between;
}

.header-wrapper-only-close {
	flex: 0 1 auto;
	display: flex;
	justify-content: flex-end;
}

.header-wrapper-only-back {
	flex: 0 1 auto;
	display: flex;
	justify-content: flex-start;
}

.close-button {
	p {
		font-size: $font-size-lg;
		font-weight: $font-weight-semibold;
		line-height: $line-height-mlg;
	}
}

.btn-back {
	background-color: transparent;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #000;
	font-size: $font-size-mmlg;
	font-weight: $font-weight-normal;

	.icon {
		color: $color-neutral-dark;
		font-size: $font-size-sm;
		font-weight: bold;
		height: 14.05px;
		width: 8.33px;
		margin-right: 8px;
	}
}

.secondary-header {
	&-wrapper {
		padding: $spacing-lg 0;
		display: flex;
		justify-content: space-between;
		background: $color-neutral-light;
		align-items: center;
		.header {
			font-size: 16px;
			font-weight: $font-weight-medium;
			line-height: 17px;
			color: $color-main;
			letter-spacing: 0;
			margin: 0;
		}

		box-shadow: 0 4px 18px rgba(0, 0, 0, 0.12);
	}

	&-back-button {
		flex: 1;
		padding: 0 $spacing-m;
	}

	&-main-heading {
		text-align: center;
		font-weight: bold;
		font-size: $font-size-mlg;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&-options {
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: end;
		padding: 0 $spacing-m;
		gap: $spacing-s;
		text-wrap: nowrap;

		&-disabled {
			pointer-events: none;
			color: $button-variant-primary-color-disabled;
		}
	}
}

.disable-box-shadow {
	box-shadow: none;
}

.headline {
	font-weight: $font-weight-bold;
	margin-bottom: $default-spacing;
	font-size: $font-size-mlg;
	line-height: $line-height-lg;

	&-semibold {
		font-weight: $font-weight-medium;
		margin-bottom: $default-spacing;
		font-size: $font-size-mlg;
	}
}

.section-separation-line {
	background-color: $color-neutral-95;
	width: 100%;
	min-height: 6px;
}
.cgi-header {
	display: flex;
	align-items: center;
	padding: $spacing-m;

	.logo {
		width: 75px;
		height: 35px;
	}
}

.cgi-events-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: sticky;
	top: 0;
	z-index: 2;
	background-color: $color-neutral-light;
	box-shadow: 0 4px 18px rgba(0, 0, 0, 0.12);
	padding: 20px 15px 8px;

	&-logo-wrapper {
		display: flex;
		align-items: center;
		gap: $spacing-s;

		.event-title {
			white-space: pre-wrap;
			font-size: $font-size-lg;
			line-height: $line-height-sm;
		}

		.logo {
			width: 51px;
			height: 24px;
		}
	}

	&-language-switcher {
	}

	.profile-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: $spacing-s;
		.cgi-event-header-language-switcher {
			.languageSwitcher {
				margin-right: 0;
			}
		}
		.header-profile {
			width: 35px;
			.profile-button {
				display: flex;
				width: 100%;
				position: relative;
				img {
					border-radius: 50%;
					height: 100%;
					object-fit: contain;
					width: 100%;
				}
				.red-dot {
					position: absolute;
					right: -5px;
					top: -5px;
				}
			}
		}
	}
}

.vertical-line {
	width: 1px;
	background: #a8a8a8;
	height: 25px;
}
