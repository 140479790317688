.textarea {
	box-sizing: border-box;
	width: 100%;
	padding: 8px;
	font-size: $font-size-lg;
	resize: none;

	&:active,
	&:focus,
	&:hover {
		border-color: $color-neutral-dark;
		outline: none;
	}

	&-hint-text {
		font-size: $font-size-sm;
		opacity: 0.5;
		margin: 0;
		padding-left: 4px;
	}

	&-error {
		border: 1px solid $color-error;
		color: $color-error;

		&::placeholder {
			color: $color-error;
		}
	}

	&-label {
		color: $color-text-label;
		display: inline-block;
		font-size: $font-size-md;
		font-weight: $font-weight-bold;
		line-height: $line-height-md;
	}
}

.textarea::placeholder {
	font-style: normal;
	color: #a8a8a8;
}
