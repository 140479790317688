$color-main: var(--color-main);
$color-main-0: var(--color-main-0);
$color-main-10: var(--color-main-10);
$color-main-20: var(--color-main-20);
$color-main-30: var(--color-main-30);
$color-main-40: var(--color-main-40);
$color-main-50: var(--color-main-50);
$color-main-60: var(--color-main-60);
$color-main-70: var(--color-main-70);
$color-main-80: var(--color-main-80);
$color-main-90: var(--color-main-90);
$color-main-95: var(--color-main-95);

$color-neutral-0: var(--color-neutral-0);
$color-neutral-10: var(--color-neutral-10);
$color-neutral-20: var(--color-neutral-20);
$color-neutral-30: var(--color-neutral-30);
$color-neutral-40: var(--color-neutral-40);
$color-neutral-50: var(--color-neutral-50);
$color-neutral-60: var(--color-neutral-60);
$color-neutral-70: var(--color-neutral-70);
$color-neutral-80: var(--color-neutral-80);
$color-neutral-90: var(--color-neutral-90);
$color-neutral-95: var(--color-neutral-95);
$color-neutral-dark: var(--color-neutral-dark);
$color-neutral-light: var(--color-neutral-light);

$color-yellow-0: var(--color-yellow-0);
$color-yellow-10: var(--color-yellow-10);
$color-yellow-20: var(--color-yellow-20);
$color-yellow-30: var(--color-yellow-30);
$color-yellow-40: var(--color-yellow-40);
$color-yellow-50: var(--color-yellow-50);
$color-yellow-60: var(--color-yellow-60);
$color-yellow-70: var(--color-yellow-70);
$color-yellow-80: var(--color-yellow-80);
$color-yellow-90: var(--color-yellow-90);
$color-yellow-95: var(--color-yellow-95);

$color-green-0: var(--color-green-0);
$color-green-10: var(--color-green-10);
$color-green-20: var(--color-green-20);
$color-green-30: var(--color-green-30);
$color-green-40: var(--color-green-40);
$color-green-50: var(--color-green-50);
$color-green-60: var(--color-green-60);
$color-green-70: var(--color-green-70);
$color-green-80: var(--color-green-80);
$color-green-90: var(--color-green-90);
$color-green-95: var(--color-green-95);

$color-blue-10: var(--color-blue-10);
$color-blue-20: var(--color-blue-20);
$color-blue-30: var(--color-blue-30);

$color-red-0: var(--color-red-0);
$color-red-10: var(--color-red-10);
$color-red-20: var(--color-red-20);
$color-red-30: var(--color-red-30);
$color-red-40: var(--color-red-40);
$color-red-50: var(--color-red-50);
$color-red-60: var(--color-red-60);
$color-red-70: var(--color-red-70);
$color-red-80: var(--color-red-80);
$color-red-90: var(--color-red-90);
$color-red-95: var(--color-red-95);

$color-transparent: var(--color-transparent);
$color-transparent-95: var(--color-transparent-95);

$background-color-main: var(--color-neutral-light);
$background-color-progress-bar: var(--color-main);

$color-checkbox-background: var(--color-neutral-light);
$color-checkbox: var(--color-main);
$color-checkbox-focus: var(--color-main-30);
$color-checkbox-disabled: var(--color-neutral-90);
$color-checkbox-checked-disabled: var(--color-neutral-90);
$color-checkbox-border: var(--color-neutral-dark);

$color-bottom-entity-info-card: var(--color-neutral-95);
$color-bottom-entity-info-description: var(--color-yellow-90);
$color-status-active: var(--color-green);
$color-status-whole-day: var(--color-red-80);
$color-status-flexible: var(--color-yellow-70);
$color-status-blocked: var(--color-neutral-dark);
$color-status-onlyCheckIns: var(--color-blue-30);

$color-error: var(--color-red-50);
$color-warning: var(--color-yellow-50);
$color-success: var(--color-green-50);
$color-info: var(--color-blue-20);
$color-disabled-state: var(--color-neutral-80);

$color-default-icon: var(--color-red-60);

$color-text: var(--color-neutral-dark);
$color-secondary-text: var(--color-neutral-dark);
$color-text-main: var(--color-main);
$color-text-special: var(--color-red-60);

//Button
$button-variant-primary-background: var(--color-main);
$button-variant-primary-background-hover: var(--color-main-90);
$button-variant-primary-background-focus: var(--color-main-80);
$button-variant-primary-background-pressed: var(--color-main-0);
$button-variant-primary-background-disabled: var(--color-neutral-95);
$button-variant-primary-border: var(--color-main);
$button-variant-primary-border-hover: var(--color-main-90);
$button-variant-primary-border-focus: var(--color-main-80);
$button-variant-primary-border-pressed: var(--color-main-0);
$button-variant-primary-border-disabled: var(--color-neutral-95);
$button-variant-primary-color: var(--color-neutral-light);
$button-variant-primary-color-hover: var(--color-main-0);
$button-variant-primary-color-focus: var(--color-main-0);
$button-variant-primary-color-pressed: var(--color-neutral-light);
$button-variant-primary-color-disabled: var(--color-neutral-80);

$button-variant-secondary-background: var(--color-neutral-95);
$button-variant-secondary-background-hover: var(--color-neutral-90);
$button-variant-secondary-background-focus: var(--color-neutral-80);
$button-variant-secondary-background-pressed: var(--color-neutral-70);
$button-variant-secondary-background-disabled: var(--color-neutral-95);
$button-variant-secondary-border: var(--color-neutral-95);
$button-variant-secondary-border-hover: var(--color-neutral-90);
$button-variant-secondary-border-focus: var(--color-neutral-80);
$button-variant-secondary-border-pressed: var(--color-neutral-70);
$button-variant-secondary-border-disabled: var(--color-neutral-95);
$button-variant-secondary-color: var(--color-main-0);
$button-variant-secondary-color-hover: var(--color-main-0);
$button-variant-secondary-color-focus: var(--color-main-0);
$button-variant-secondary-color-pressed: var(--color-main-0);
$button-variant-secondary-color-disabled: var(--color-neutral-80);

$button-variant-tertiary-background: var(--color-transparent);
$button-variant-tertiary-background-hover: var(--color-transparent);
$button-variant-tertiary-background-focus: var(--color-transparent);
$button-variant-tertiary-background-pressed: var(--color-main-90);
$button-variant-tertiary-background-disabled: var(--color-transparent);
$button-variant-tertiary-color: var(--color-neutral-0);
$button-variant-tertiary-color-hover: var(--color-main);
$button-variant-tertiary-color-focus: var(--color-main-0);
$button-variant-tertiary-color-pressed: var(--color-main-0);
$button-variant-tertiary-color-disabled: var(--color-neutral-90);

$button-variant-empty-background: var(--color-transparent);
$button-variant-empty-background-hover: var(--color-transparent-95);
$button-variant-empty-background-focus: var(--color-transparent-95);
$button-variant-empty-background-pressed: var(--color-transparent-95);
$button-variant-empty-background-disabled: var(--color-transparent-95);
$button-variant-empty-color: var(--color-main);
$button-variant-empty-color-hover: var(--color-main);
$button-variant-empty-color-focus: var(--color-main);
$button-variant-empty-color-pressed: var(--color-main);
$button-variant-empty-color-disabled: var(--color-neutral-80);

$button-variant-outline-background: var(--color-neutral-light);
$button-variant-outline-background-hover: var(--color-main-95);
$button-variant-outline-background-focus: var(--color-main-90);
$button-variant-outline-background-pressed: var(--color-main-90);
$button-variant-outline-background-disabled: var(--color-neutral-light);
$button-variant-outline-border: var(--color-main);
$button-variant-outline-border-hover: var(--color-main);
$button-variant-outline-border-focus: var(--color-main);
$button-variant-outline-border-pressed: var(--color-main);
$button-variant-outline-border-disabled: var(--color-neutral-95);
$button-variant-outline-color: var(--color-main);
$button-variant-outline-color-hover: var(--color-main);
$button-variant-outline-color-focus: var(--color-main);
$button-variant-outline-color-pressed: var(--color-main);
$button-variant-outline-color-disabled: var(--color-neutral-80);
$button-variant-outline-icon-color-disabled: var(--color-neutral-0);

$button-variant-negative-background: var(--color-red-70);
$button-variant-negative-background-pressed: var(--color-red-80);
$button-variant-negative-background-disabled: var(--color-red-95);
$button-variant-negative-border: var(--color-red-70);
$button-variant-negative-border-pressed: var(--color-red-80);
$button-variant-negative-border-disabled: var(--color-neutral-95);
$button-variant-negative-color: var(--color-neutral-light);
$button-variant-negative-color-pressed: var(--color-neutral-light);
$button-variant-negative-color-disabled: var(--color-neutral-80);

$color-questionnaire-hr: var(--color-neutral-80);

//Statistics
$color-background-stats-header: var(--color-neutral-95);

//Map
$color-background-map: var(--color-neutral-95);
$color-status-free: var(--color-green-50);
$color-status-fully-booked: var(--color-red-60);
$color-status-blocked: var(--color-neutral-80);

//Your Booking Card
$color-input-reservation-detail: var(--color-neutral-95);

//Date Time Picker
$color-border-input-datetimepicker: var(--color-neutral-60);

//Badge Card
$color-background-profile-photo: var(--color-neutral-dark);

//Booking Card
$color-background-booking-info: var(--color-neutral-95);

//Error
$color-error: var(--color-red-50);

//Gradient
$gradient-cgi: linear-gradient(90deg, #e31937 0%, #a82465 60%, #5236ab 100%);
$gradient-cgi-vertical: linear-gradient(#e31937 0%, #a82465 60%, #5236ab 100%);

@import './purpletheme';
@import './redtheme';
