.content-switcher-wrapper {
	.content-switcher {
		display: flex;

		&-item {
			padding: $spacing-m $spacing-md 0px;
			flex: 1;
			justify-content: center;
			align-items: center;
			text-align: center;
			font-size: $font-size-md;
			line-height: $line-height-sm;
			font-weight: $font-weight-medium;
			display: flex;
			flex-direction: column;
			gap: $spacing-xs;

			.purple-bar {
				margin-top: $padding-sm;
				background: $color-main;
				min-height: 4px;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				-webkit-transition: width 0.1s linear;
				-moz-transition: width 0.1s linear;
				-o-transition: width 0.1s linear;
				transition: width 0.1s linear;
				width: 0px;
				&-active {
					width: 100%;
				}
			}			
		}
		.active {
			color: $color-main;
		}
	}

	.dividing-hr {
		margin-inline: -$spacing-m;
	}
}
