// Base styles: normalize, mixins, variables
@import 'base/variables';
@import 'base/themes';
@import 'base/mixins-and-includes';
@import 'base/normalize';
@import 'base/typography';
// Placeholder styles
@import '../../node_modules/placeholder-loading/src/scss/placeholder-loading';
@import 'rc-time-picker/assets/index.css';

// Typography
@import '@fontsource/roboto';
@import '@fontsource/source-sans-pro';

// Common elements
@import 'elements/commonElements';
@import 'elements/comment';
@import 'elements/dropdown';
@import 'elements/bottommenu';
@import 'elements/buttons';
@import 'elements/checkboxes';
@import 'elements/roundcheckbox';
@import 'elements/radiobutton';
@import 'elements/icons';
@import 'elements/inputs';
@import 'elements/headings';
@import 'elements/languageswitcher';
@import 'elements/textareas';
@import 'elements/primaryinput';
@import 'elements/primaryinput';
@import 'elements/searchinput';
@import 'elements/accordion';
@import 'elements/switcher';
@import 'elements/imageLoader';
@import 'elements/errors';
@import 'elements/chips';
@import 'elements/title';
@import 'elements/dateTimePicker.scss/singleDateTimePicker';
@import 'elements/contentSwitcher';
@import 'elements/commontTimePicker';

// Components
@import 'components/agenda/agenda';
@import 'components/agenda/agendaItem.scss';

@import 'components/checkAvailability/checkAvailability';
@import 'components/checkAvailability/dailyCalendarView';

@import 'components/dashboard/dashboard';
@import 'components/dashboard/officeInfo';
@import 'components/dashboard/officeDescription';
@import 'components/dashboard/contactInfo';

@import 'components/booking/mapView/mapzoneview';
@import 'components/booking/mapView/searchPage';
@import 'components/booking/mapView/mapViewOverlay';
@import 'components/booking/mapView/mapView';
@import 'components/booking/mapView/bottomMapView';
@import 'components/booking/mapView/entityInfoBottomCard';

@import 'components/daySelection/daySelection';
@import 'components/cards/cards';
@import 'components/cards/successCard';
@import 'components/loginpage/loginpage';
@import 'components/qrscanner/qrscanner';

@import 'components/settings/settings';
@import 'components/settings/settingsItem';
@import 'components/settings/bookingSettings';

@import 'components/settings/dataPrivacy/dataPrivacy';

@import 'components/settings/colleagueList/colleagueList';
@import 'components/settings/colleagueList/colleagueItem';

@import 'components/settings/reportProblem/reportproblem';
@import 'components/settings/reportProblem/myReports';
@import 'components/settings/reportProblem/meetingRoomProblem';
@import 'components/settings/reportProblem/appTechnicalProblem';
@import 'components/settings/reportProblem/deskProblem';
@import 'components/settings/reportProblem/facilityProblem';

@import 'components/profilepage/profilepage';
@import 'components/profilepage/memberProfile';
@import 'components/profilepage/memberProfileEdit';
@import 'components/profilepage/badgepage';


@import 'components/organizationSwitcher/organizationSwitcher.scss';

@import 'components/actionSheet/actionSheet';

@import 'components/areaListPage/areaListPage';

@import 'components/verifyOTP/verifyOTP';

@import 'components/verifyOTP/profilecompletion';

@import 'components/verifyOTP/officeselection';

@import 'components/verifyOTP/heading';

@import 'components/common/featuresListPage/featuresListPage';

@import 'components/common/sensorInfo/sensorInfo';

@import 'components/common/attendeeChainNames';

@import 'components/event/eventIntro.scss';
@import 'components/event/eventCountdown.scss';
@import 'components/event/eventPage.scss';
@import 'components/event/iframePage.scss';
@import 'components/event/session/sessionDetails.scss';
@import 'components/event/session/askQuestion.scss';
@import 'components/event/session/sessionInfo.scss';
@import 'components/event/session/rateSession.scss';
@import 'components/event/eventAgenda/eventAgenda.scss';
@import 'components/event/eventAgenda/bookableSession.scss';
@import 'components/event/eventSupport.scss';
@import 'components/event/eventAttendees/eventAttendees.scss';
@import 'components/event/eventAttendees/filterAttendees.scss';
@import 'components/event/eventAttendees/attendeePage.scss';
@import 'components/event/session/speakerPage.scss';
@import 'components/eventForm/eventForm';

@import 'components/eventInvitations/eventInvitations';
@import 'components/eventInvitations/registrationForm';
@import 'components/eventInvitations/registrationFeedback';

@import 'components/officeElement/officeElement';

@import 'components/profilepage/registrationFormDetails';