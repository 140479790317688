.page-content {
  .dashboard-wrapper {
    position: relative;
    height: 90%;
    padding-bottom: 200px;
    display: flex;
    flex-flow: column;
    overflow-y: scroll !important;
    background-color: $background-color-main;

    .event-redirect {
      background-color: $color-main;
      background-position: center;
      background-size: cover;
      margin: $spacing-m;
      border-radius: 12px;
      padding: $spacing-m;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-size: $font-size-mmlg;
      position: relative;

      p {
        margin: $spacing-xs;
        color: $color-neutral-60;
      }
      .name {
        font-weight: $font-weight-bold;
        font-size: $font-size-mmlg;
      }
      .info {
        font-size: $font-size-lg;
      }
      .details {
        z-index: 0;
      }
      .icon-wrapper {
        min-width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-main-30;
        border-radius: 50%;
        z-index: 0;
      }
      .event-redirect-overlay {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 12px;
        background-color: white;
        opacity: 75%;
        width: 100%;
        height: 100%;
      }
    }
  }
}
