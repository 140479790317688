.display {
	&-large {
		line-height: 64px;
		font-size: 57px;
		font-weight: $font-weight-normal;
		letter-spacing: 0;
		margin: 0;
	}
	&-medium {
		line-height: 52px;
		font-size: 45px;
		font-weight: $font-weight-normal;
		letter-spacing: 0;
		margin: 0;
	}

	&-small {
		line-height: 44px;
		font-size: 38px;
		font-weight: $font-weight-medium;
		letter-spacing: 0;
		margin: 0;
	}
}
.headline {
	&-large {
		font-weight: $font-weight-medium;
		font-size: 38px;
		line-height: 48px;
		letter-spacing: 0;
		margin: 0;
	}
	&-medium {
		line-height: 36px;
		font-size: 28px;
		font-weight: $font-weight-normal;
		letter-spacing: 0;
		margin: 0;
	}
	&-small {
		font-weight: $font-weight-medium;
		font-size: 30px;
		line-height: 38px;
		color: $color-main;
		letter-spacing: 0;
		margin: 0;
	}
}
.title {
	&-large {
		line-height: 28px;
		font-size: 22px;
		font-weight: $font-weight-normal;
		letter-spacing: 0.15;
		margin: 0;
	}
	&-medium {
		line-height: 24px;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0.1;
		margin: 0;
	}
	&-small {
		line-height: 20px;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0.1;
		margin: 0;
	}
}
.label {
	&-large {
		line-height: $line-height-md;
		font-size: $font-size-md;
		font-weight: $font-weight-bold;
		letter-spacing: 0.1;
		margin: 0;
	}
	&-medium {
		line-height: 16px;
		font-size: $font-size-sm;
		font-weight: 500;
		letter-spacing: 0.5;
		margin: 0;
	}
	&-small {
		line-height: 16px;
		font-size: 11px;
		font-weight: 500;
		letter-spacing: 0.5;
		margin: 0;
	}
}

.body {
	&-large {
		line-height: 24px;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0.5;
		margin: 0;
	}
	&-medium {
		line-height: 20px;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0.25;
		margin: 0;
	}
	&-small {
		line-height: 20px;
		font-size: 14px;
		font-weight: 500;
		margin: 0;
	}
}

.caption {
	&-default {
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		margin: 0;
	}
}

h3 {
	font-weight: 600;
	font-size: 24px;
	line-height: 30px;
	margin: 0px;
}

h4 {
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	margin: 0px;
}