.registration-form-details {
	&-profile-page {
		height: 100%;
		display: flex;
		flex-direction: column;
		&-content {
			padding: $padding-sm;
			padding: 16px 16px 32px 16px;
			overflow-y: scroll;
			&-title {
				font-size: $font-size-lg;
				color: $color-main-50;
			}

			ul {
				list-style: none;
				padding: 0;
				padding-top: 1px;
				li {
					.header {
						color: $color-neutral-90;
						font-size: $font-size-md;
						font-weight: $font-weight-normal;
						line-height: $line-height-lgx;
						margin-bottom: 0;
						margin-top: 0;
					}
					.content {
						font-size: $font-size-lg;
						font-weight: $font-weight-bold;
						line-height: $line-height-lgx;
						color: $color-neutral-dark;
						margin-top: 0;
					}
				}
			}
		}
	}
}
.edit-form {
	display: inline-flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: $spacing-xs;

	.custom-icon {
		width: 20px;
		height: 20px;
	}
}
.registration-form-details-wrapper-loading {
	display: flex;
	justify-content: center;
	margin-top: 25rem;
}