.event-agenda-page {
	height: 100%;
	display: flex;
	flex-flow: column;

	&-content {
		display: flex;
		flex-flow: column;
		height: calc(100% - 66px); //content height without header
		.month {
			text-align: center;
			font-size: $font-size-mmlg;
			color: $color-neutral-60;
			font-weight: $font-weight-semibold;
		}

		&-days {
			display: flex;
			align-items: center;
			overflow-x: auto;
			border-top: 1px solid $color-neutral-95;
			padding: $spacing-xs 0;
			box-shadow: 0 8px 20px -10px rgb(0 0 0 / 10%);

			.day-item-wrapper:first-child {
				margin-left: auto;
			}

			.day-item-wrapper:last-child {
				margin-right: auto;
			}
		}
		&-agenda {
			flex: 1;
			overflow-y: scroll;
			padding: $spacing-m;
			padding-bottom: 0px;
			.dnd-wrapper {
				width: 100%;
				position: relative;

				.hour-skeleton-item {
					height: 120px;
					display: flex;
					justify-content: flex-end;
					position: relative;

					.hour-mark {
						font-size: 14px;
						font-weight: 400;
						line-height: 20px;
						text-align: left;
						color: #333333;
						margin-top: -5px;
						margin-right: $spacing-m;
						text-align: center;

						p {
							margin: 0;
						}
					}

					.hour-item-empty {
						flex: 1;
						border-top: 1px dashed $color-neutral-95;
					}
				}

				.current-hour-mark {
					position: absolute;
					width: calc(100% - 40px);
					right: 0;
					display: flex;
					align-items: center;
					z-index: 1;

					.dot-hour-mark {
						width: 7px;
						height: 7px;
						border-radius: 50%;
						background: $color-main;
					}

					.line-hour-mark {
						height: 2px;
						width: 100%;
						background: $color-main;
					}
				}

				.agenda-item {
					background-color: $color-neutral-light;
					width: calc(100% - 50px);
					border-left: 6px $color-red-50 solid;
					position: absolute;
					right: 0;
					padding: $spacing-xs $spacing-xs $spacing-xs $spacing-s;
					box-sizing: border-box;
					@include md-box-shadow;
					overflow: hidden;

					.description {
						margin-top: $spacing-xs;
						color: #151515;
						opacity: 0.45;
					}

					&.removed-padding {
						padding-top: 0px;
						padding-bottom: 0px;
					}

					.horizontal-items {
						display: flex;
						gap: $spacing-xxs;
						align-items: center;
					}

					.description {
						display: -webkit-box;
						-webkit-box-orient: vertical;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}
			.main-list-wrapper {
				height: 100%;
			}

			&-loading {
				display: flex;
				justify-content: center;
				margin-top: 10%;
			}
		}
	}
}
