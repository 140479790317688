//history item css = only contains styling for history items that look like chat items

.comment-items-container {
	display: flex;
	flex-direction: column;
	gap: $spacing-m;
	.comment-item {
		display: flex;
		align-items: end;
		gap: $spacing-m;

		&.own-comment {
			flex-flow: row-reverse;

			.comment-item-member {
				background-color: $color-main-0;
			}
		}

		&-content {
			width: 100%;
			background-color: $color-transparent;
			padding: $spacing-m;
			border: 1px solid $color-main;
			p {
				margin: 0;
			}

			span {
				white-space: pre-wrap;
			}

			&-flex {
				display: flex;
				gap: $spacing-xs;
			}
		}

		.history-item-content {
			background-color: #f8f8f8;
			border: none;

			.comment-item-content-flex {
				color: $color-neutral-80;
			}
		}
	}
}

.comment-item-member {
	min-width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	color: $color-neutral-light;
	background-color: $color-yellow-50;
}