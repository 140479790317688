.report-problem-card-wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: $spacing-m;

	.report-problem-item {
		&-card {
			cursor: pointer;
			@include sm-box-shadow;

			.icon-wrapper {
				padding: $spacing-xlg 0px;
				background-color: #f2f1f9;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;

				.custom-icon {
					width: 48px;
					height: 48px;
					fill: $color-main;
				}

				svg {
					width: 48px;
					height: 48px;
				}
			}
		}

		&-content {
			padding: $spacing-xs $spacing-m;

			p {
				font-size: 14px;
				font-weight: 400;
				line-height: 17px;
				color: #767676;
			}
			.bold {
				font-weight: 700;
				line-height: 20px;
				color: $color-neutral-dark;
			}
		}
	}
}
