// Images paths
$login-background-image-path: '../../../image/login_image.png';
$dashboard-dusseldorf-image-path: '../../../image/dusseldorf.png';

// Defaults
$default-spacing: 15px;
$default-border-radius: 15px;
$default-max-app-width: 450px;
$default-max-app-height: 930px;

// Colors
$color-neutral-light: #fff;
$color-neutral-dark: #000;

// Yellow
$color-yellow-90: #ffe6bd;
$color-yellow-70: #ffbf58;
$color-yellow-50: #ffac25;
$color-yellow-20: #e29312;

// Green
$color-green-50: #2ac769;
$color-green-95: #d8f6e5;
$color-green-50: #007f7f;

// Blue
$color-blue-10: #cce8fe;
$color-blue-20: #78c3fe;
$color-blue-30: #5bacec;
$color-blue-40: #2f80ed;

// Red
$color-red-95: #f8dadf;
$color-red-70: #ff5858;
$color-red-50: #e31937;
$color-red-60: #d2062b;
$color-red-60: #d2062b;
$color-red-70: #db093b;
$color-red-80: #ed6787;

// Magenta
$color-red-95: #fbdadf;
$color-red-80: #cb7ca3;
$color-red-60: #a82465;

// Gradient for headings
$color-gradient-first: #db093b;
$color-gradient-second: #a22064;
$color-gradient-third: #5135a8;
$purple-gradient-list: $color-gradient-first, $color-gradient-second, $color-gradient-third;

// Gradient for cards
$color-grey-gradient-first: #ebebeb;
$color-grey-gradient-second: #fcfcfc;
$gray-gradient-list: $color-grey-gradient-first, $color-grey-gradient-second;

$color-heading: #464646;
$color-sub-heading: #1a0404;
$color-text-label: #333333;
$color-time-label: #969696;

// Font weights
$font-weight-black: 900;
$font-weight-bold: 700;
$font-weight-medium: 600;
$font-weight-semibold: 500;
$font-weight-normal: 400;
$font-weight-light: 300;

// Fonts sizes
$font-size-xxlg: 32px;
$font-size-xlg: 24px;
$font-size-mlg: 20px;
$font-size-mmlg: 18px;
$font-size-lg: 16px;
$font-size-md: 14px;
$font-size-m: 13px;
$font-size-sm: 12px;
$font-size-xsm: 10px;

// Line heights
$line-height-xlg: 30px;
$line-height-mlg: 25px;
$line-height-lg: 23px;
$line-height-lgx: 21px;
$line-height-md: 18px;
$line-height-sm: 16px;
$line-height-xsm: 14px;
$line-height-supersm: 11px;

// Spacings
$spacing-xxxs: 2px;
$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-s: 12px;
$spacing-m: 16px;
$spacing-md: 20px;
$spacing-lg: 24px;
$spacing-xlg: 32px;
$spacing-xxlg: 40px;

// Letter spacing
$letter-spacing-xlg: 4px;
$letter-spacing-lg: 1.5px;
$letter-spacing-md: 0.5px;

// Font family
$body-font-family: 'Source Sans Pro', 'Roboto', 'sans-serif';

// $breakpoints for the screen width
$breakpoint-xxs: 280px;
$breakpoint-xs: 320px;
$breakpoint-sm: 360px;
$breakpoint-md: 375px;
$breakpoint-lg: 410px;
$breakpoint-xlg: 450px;
$breakpoint-xxlg: 480px;

//breakpoints for the screen height
$breakpoint-height-xs: 580px;
$breakpoint-height-sm: 670px;
$breakpoint-height-sml: 736px;
$breakpoint-height-md: 812px;
$breakpoint-height-mdl: 844px;
$breakpoint-height-lg: 930px;
$breakpoint-height-xlg: 950px;

//Padding or Margin Spacing
$padding-xxs: 4px;
$padding-xs: 8px;
$padding-sm: 12px;
$padding-s: 16px;
$padding-sml: 20px;
$padding-m: 24px;
$padding-ml: 32px;
$padding-l: 36px;

$default-min-app-width: 320px;
