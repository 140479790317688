.rating-wrapper {
    display: flex;
    flex-direction: column;
}

.divider {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: $spacing-md;
    margin: $padding-sm 0;

    hr {
        flex: 1;
        border: 0;
        border-top: 1px solid #e8e8e8;
    }

    h4 {
        color: #666666;
        margin: 0 $padding-s;
    }
}

.html-container {
    margin: 0;
    p:first-child {
        margin-top: 0;
    }
}