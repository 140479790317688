.filter-attendees-page {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	flex-flow: column;

	&-header {
		width: 100%;
	}

	&-content {
		color: $color-neutral-60;
		width: 100%;
		flex-grow: 2;
		display: flex;
		flex-flow: column;
		overflow-y: scroll;

		&-search-wrapper {
			padding-block: $spacing-m;

			.search-input {
				border-radius: 4px;
			}
		}
		&-selected-filters {
			display: flex;
			margin: $spacing-m;
			margin-top: 0;
			flex-wrap: wrap;
			gap: $spacing-xs;


			.chip {
				font-size: $font-size-lg;
				font-weight: $font-weight-bold;
				padding: $spacing-xxs $spacing-xs;

				.icon {
					width: 15px;
					height: 15px;
				}
			}
		}

		&-filter-options {
			.accordion {
				&-wrapper {
					margin: 0;
				}

				&-title,
				&-title-option {
					box-shadow: none;
					border-bottom: 1px solid $color-neutral-90;
					padding: $spacing-m;
					font-size: $font-size-mmlg;
					border-radius: 0px;
					font-weight: $font-weight-medium;
					p {
						margin: 0;
					}
				}
				&-title-option {
					margin: 0;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}

				&-content {
					box-shadow: none;
					font-size: $font-size-mmlg;
					background-color: white;
					padding: 0;
					margin: 0;
					&-item {
						padding: $spacing-m 28px;
						margin: 0;
						border-bottom: 1px solid $color-neutral-90;
						display: flex;
						justify-content: space-between;
						align-items: center;

						.icon {
							width: 20px;
							height: 15px;
						}

						p {
							margin: 0;
							text-align: left;
							line-height: $line-height-lgx;
						}
					}
					&-item:hover {
						background-color: $color-neutral-95;
					}
				}
			}
		}
	}
}

.loader-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
}