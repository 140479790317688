.event-intro-page {
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: column;
	text-align: center;
	align-items: center;
	background-color: $color-main;
	background-position: center;
	background-size: cover;

	&-wrapper {
		display: flex;
		flex-flow: column;
		text-align: center;
		background: rgba(0, 0, 0, 0.25);
		height: 100%;
		width: 100%;
	}
	&-details {
		flex-grow: 2;
		display: flex;
		flex-flow: column;
		justify-content: center;

		p {
			margin: 0;
			color: $color-neutral-light;
		}

		.header {
			margin: 48px 30px;
			font-size: 58px;
			font-weight: $font-weight-bold;

			span {
				font-size: 54px;
				font-weight: 100;
				text-transform: uppercase;
			}
		}

		.event-details {
			ol {
				margin: 0px;
				padding: 0px;
				list-style-position: inside;
			}
			ul {
				list-style: none;
				li {
					&::before {
						content: '•';
						margin-right: 5px;
					}
				}
			}
			font-size: $font-size-lg;
			margin: 0 60px;
		}
	}

	&-actions {
		display: flex;
		flex-flow: column;
		text-align: center;
		align-items: center;
		width: 100%;
		margin-bottom: 100px;

		.button {
			background-color: $color-neutral-light;
			font-size: $font-size-mmlg;
			font-weight: $font-weight-semibold;
			color: $color-main;
			cursor: pointer;
			border: none;
		}

		.button:hover {
			background-color: $color-neutral-95;
		}

		.back-to-dj {
			font-size: $font-size-mlg;
			color: $color-neutral-light;
			font-weight: $font-weight-semibold;
			margin: $spacing-m;
			cursor: pointer;
		}
	}
}
