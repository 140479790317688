.dropdown {
	&-label {
		color: $color-text-label;
		display: inline-block;
		font-size: $font-size-md;
		line-height: $line-height-md;

		&-bold {
			font-weight: $font-weight-bold;
		}
	}
}
