.image-loader {
	background-color: #f2f1f9;
	display: flex;
	flex-direction: column;
	padding: $spacing-lg $spacing-m;
	gap: $spacing-xlg;
	align-items: center;
	justify-content: space-between;

	.warning {
		color: $color-red-60;
		font-size: $font-size-md;
		margin: $default-spacing 0;
	}

	.close-icon {
		font-size: 30px;
		margin-left: 10px;
	}

	.images-list {
		width: 100%;
		gap: 8px;
	}

	.image-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		.image-name {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 16px;
			font-weight: 700;
			line-height: 20px;
		}

		.close-icon {
			width: 28px;
			height: 28px;
			cursor: pointer;
		}
	}

	.add-more {
		display: inline-block;
		cursor: pointer;

		.icon {
			margin-right: 5px;
			vertical-align: middle;
		}
	}

	.quantity {
		color: $color-neutral-60;
		font-size: $font-size-sm;
		border-bottom: 1px solid $color-neutral-60;
		padding-bottom: $default-spacing;
	}

	&-content {
		&.align-start {
			align-items: start;
		}
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: $spacing-xs;
		align-items: center;

		&-restrictions {
			text-align: center;
		}

		.dividing-hr {
			background-color: #A8A8A8;
			margin-top: $spacing-m
		}

		&.empty-content {
			.header {
				font-size: 16px;
				font-weight: 700;
				line-height: 20px;
				text-align: center;
				color: #333333;
			}

			.description {
				color: #767676;
			}
		}

		.header {
			font-size: 18px;
			font-weight: 400;
			line-height: 23px;
		}

		.description {
			font-size: 14px;
			font-weight: 400;
			line-height: 17px;
			color: #333333;
		}

		.custom-icon {
			width: 48px;
			height: 48px;
		}

		svg {
			width: 48px;
			height: 48px;
		}
	}

	.hidden {
		width: 0px;
		height: 0px;
		opacity: 0;
		position: absolute;
		z-index: -1;
	}
}
