.common-time-wrapper {
	width: 100px;
	display: flex;
	flex-direction: column;

	.time-label {
		text-align: left;
	}

	.desk-time {
		width: 100px;
		border-radius: 10px;
		box-shadow: none;
		border-color: $color-border-input-datetimepicker;
		border-style: solid;
		border-width: 1px;
		padding: 5px;
		display: flex;
		align-items: center;
		margin-top: 0;

		.icon {
			width: 25px !important;
			height: 25px !important;
		}

		.time {
			border: none;

			&:focus {
				outline: none;
			}
		}
		input[type='time']::-webkit-calendar-picker-indicator,
		input[type='time']::-ms-clear,
		input[type='time']::-webkit-clear-button,
		input[type='time']::-webkit-outer-spin-button,
		input[type='time']::-webkit-inner-spin-button,
		input[type='time']::-webkit-outside-spin-button,
		input[type='time']::-webkit-datetime-edit-ampm-field {
			display: none;
		}

		input[type='time'] {
			-moz-appearance: textfield;
		}
	}
}
