.colleague-item-wrapper {
	display: flex;
	align-items: center;
	margin: 10px 0;
	position: relative;

	.checkbox-wrapper {
		margin-left: 16px;
	}

	.photo-wrapper {
		margin: 0 10px;
		position: relative;

		img {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			object-fit: cover;
		}

		.ph-item,
		.ph-row,
		.ph-col-12 {
			padding: 0;
			margin: 0;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			border: none;
		}
		
		.check-icon {
			position: absolute;
			top: 0px;
			right: 0px;
			background-color: white;
			border-radius: 50%;
			width: 16px;
			height: 16px;
		}
	}

	.info-content {
		.name-status-wrapper {
			display: flex;
			align-items: center;
			.name {
				margin: 0;
				font-weight: bold;
				font-size: $font-size-lg;
				max-width: 250px;
			}

			.status-loading {
				.ph-item {
					padding: 0;
					margin: 0;
					margin-left: $spacing-xs;
					border: none;
				}

				.ph-row,
				.ph-col-12 {
					height: 20px;
					width: 60px;
					padding: 0;
					margin: 0;
					border-radius: 12px;
				}
			}
			.status {
				font-weight: bold;
				font-size: $font-size-sm;
				padding: $spacing-xxxs $spacing-s;
				border-radius: 12px;
				margin-left: $spacing-xs;

				p {
					margin: 0;
				}
			}

			.status-available {
				background-color: $color-green-95;
				color: $color-green-50;
			}

			.status-busy {
				background-color: $color-red-95;
				color: $color-red-60;
			}
		}

		.email {
			margin: 0;
			margin-top: 5px;
			color: $color-neutral-60;
			font-size: $font-size-md;
			max-width: 250px;
		}
	}

	.remove-action {
		position: absolute;
		right: 10px;
		font-size: $font-size-xlg;
		color: $color-neutral-60;
	}

	.cancelled {
		color: $color-red-50;
		font-size: $font-size-sm;
		text-align: right;
		flex-grow: 1;
	}
}

.hr-colleague-item-bottom {
	margin: 0;
	height: 1px;
	background-color: $color-neutral-95;
	outline: none;
	border: none;
}

.added-colleague {
	margin: 0;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	border-bottom-color: $color-neutral-95;
	padding: $default-spacing 0;

	.info-content {
		display: flex;
		flex-flow: column;
		align-items: left;
		width: 100%;
	}
}
