.map-view-container {
	.bottom-map-view {
		position: absolute;
		width: 100%;
		bottom: 20px;
		z-index: 6;

		.action-button-wrapper {
			display: flex;
			flex-flow: column;
			align-items: flex-end;
			justify-content: center;
			gap: $spacing-xs;
			margin: $spacing-m;
		}
		.action-button {
			min-width: 40px;
			height: 40px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 2;

			.icon {
				width: 20px;
				height: 20px;
			}
		}
		.menu-options {
			display: flex;
			flex-flow: column;
			align-items: flex-end;
			position: relative;
			width: 100%;

			.menu-option {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: $spacing-m;
				position: absolute;
				z-index: 1;
				color: $color-neutral-light;
				bottom: 0;

				p {
					margin: 0;
					display: none;
				}
			}

			.menu-option-office-element-show {
				bottom: 100px;
				transition: 0.5s;
				p {
					display: inline;
				}
			}
			.menu-option-desk-show {
				bottom: 50px;
				transition: 0.6s;
				p {
					display: inline;
				}
			}
		}

		.find-office-element-action-button {
			background-color: $color-green-50;
		}

		.find-desk-action-button {
			background-color: $color-main-80;
		}

		.zone-action-button {
			background-color: $color-neutral-light;
			z-index: 2;
			border: 1px solid #c4c4c4;
			transition: 0.5s;
		}

		.menu-action-button {
			background-color: $color-main;
			z-index: 2;
		}
		.menu-action-button-open {
			background-color: $color-neutral-light;

			.icon {
				width: 10px !important;
			}
		}
		.map-view-overlay {
		}
	}
}
