.rate-session-page {
    display: flex;
    flex-flow: column;
    height: 100%;

    &-content {
        flex-grow: 1;
        overflow-y: scroll;
        padding: $spacing-xlg $spacing-m;

        &-details {
            margin-bottom: $spacing-xlg;
        }

        .rate-session-page-content-input {
            margin-bottom: 64px;
        }
        .rate-session-textarea {
            margin-top: $spacing-xs;
            margin-bottom: $spacing-xlg;
            width: 100%;
            height: 200px
        }

        .stars-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: $spacing-xlg;
        }

    }
}